import React, { useEffect, useState } from 'react'
import details from "./ProductDetails.module.css"
import img1 from "../assets/img1.jpg"
import img2 from "../assets/img2.jpg"
import img3 from "../assets/img3.jpg"
import { useNavigate, useParams } from 'react-router'
import { useCartBadge } from '../ContextPage'
import Message from '../Messages/Message'
import { collection, getDocs, query, where } from 'firebase/firestore'
import db from '../Firebase'
import Loader from "react-js-loader";
import imageS from "../assets/imageS.jpeg";
import Footer from './Footer'


const ProductDetails = () => {
    const [data,setData] = useState([]);
    const [quantity,setQuantity] = useState(1);
    const [Total,setTotal] = useState(0);
    const {cart,setCart} = useCartBadge();
    const [message,setMessage] = useState(false)
    const {search,productType} = useParams();

    const navigate = useNavigate();

    const getData = async()=>{
        const q = query(collection(db, productType), where("id", "==", search));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          setData(doc.data());
          console.log(doc.data());
        }); 
    }

    const addToCart =async()=>{
        const cartItems = {
            name:data.title,
            description:data.desc,
            price:data.price2,
            total:Total,
            quantity:quantity,
            img:data.imgUrl,
            id:data.id
        }
        const cart = await JSON.parse(localStorage.getItem("cozimaCart"));
        if (cart != null) {
            let newCart = [];
            newCart.push(...cart,cartItems);
            localStorage.setItem("cozimaCart",JSON.stringify(newCart));
        }else{
            localStorage.setItem("cozimaCart",JSON.stringify([cartItems]));
        }
        setCart(prev => prev + 1);
        setMessage(true);
        navigate("/cart");
    }

    useEffect(()=>{
        if (data?.length < 1) {
            window.scrollTo(0,0);
        getData();
        }
    },[]);

    useEffect(()=>{
        setTotal(quantity * data?.price2);
    },[quantity,data])
    useEffect(()=>{
        if (quantity < 1) {
            setQuantity(1);
        }
    },[quantity]);

    useEffect(()=>{
        if (message) {
            
    setTimeout(() => {
        setMessage(false);
    }, 3500);
        }
    },[message]);

  return (
    <div>
    <div className={details.productsDetails}>
      <div className={details.product}>
        <center>
        <div className={details.infos}>
            <div className={details.left}>
         

         {data.length != 0  && (<img src={data?.imgUrl} alt="" /> )}
{data.length == 0 && (<Loader type="spinner-default" bgColor={"blue"} color={"blue"} title={""} size={100} />)}

</div>
             
            <div className={details.right}>
                <div style={{width:"90%",textAlign:"left"}}>
                <h1>{data?.title}</h1>
                <br />
                <h4 style={{marginBottom:"5px"}}>Description:</h4>
                <p>{data?.desc}</p>
                <div className={details.pricing}>
                    <p className={details.oldPrice}>{data?.price} DH</p>
                    <p className={details.price}>{data?.price2} DH</p>
                </div>
                <br />
                <h4>Choose quantity</h4>
                <div className={details.controls}>
                    <input value={quantity} type="number" />
                    <button onClick={()=> setQuantity(prev => prev +1)}>+</button>
                    <button onClick={()=> setQuantity(prev => prev -1)}>-</button>
                    <br />
                </div>
                <div className={details.total}>
                    <h4>Total:</h4>
                    <p>{Total} DH</p>
                </div>
                <button disabled={message} onClick={addToCart} className={details.buy}>اشتري الان</button>
                </div>
            </div>
        </div>

        <div style={{width:"112%",marginLeft:"-6%",marginTop:"15px"}}> 
        <img className={details.imageS} src={imageS} alt="" />
        </div>

        <div className={details.bottomContainer}>
            <center><h3>Fiche technique</h3></center>
        <div className={details.bottom}>
            <div className={details.bottomRight}>
                <div className={details.bottomTitle}>
                    <h4>Principales caracteristiques</h4>
                </div>
                <div style={{marginLeft:"10px",marginTop:"15px",textAlign:"left"}}>
                        <p>{data?.caracter}</p>
                    </div> 
            </div>
            <div className={details.bottomLeft}>
            <div className={details.bottomTitle}>
                    <h4>Descriptif technique</h4>
            </div>
            <div style={{marginLeft:"10px",marginTop:"15px", textAlign: "left"}}>
            <span style={{width:"100%",display:"flex",alignItems:"center"}}><p><span style={{fontWeight:"bold"}}>SKU: </span> {data?.id}</p></span>
            <span style={{width:"100%",display:"flex",alignItems:"center"}}><p><span style={{fontWeight:"bold"}}>Modele: </span> {data?.modele}</p></span>
            <span style={{width:"100%",display:"flex",alignItems:"center"}}><p><span style={{fontWeight:"bold"}}>Poids: </span> {data?.poids} Kg</p></span>
            <span style={{width:"100%",display:"flex",alignItems:"center"}}><p><span style={{fontWeight:"bold"}}>Couleur: </span> {data?.couleur}</p></span>
            <span style={{width:"100%",display:"flex",alignItems:"center"}}><p><span style={{fontWeight:"bold"}}>Matiere principale: </span> {data?.materiel}</p></span>

            </div>
            </div>

        </div>
        </div>
        
        </center>
      </div>
    </div>
    {message && (
        <Message message={"Produit bien ajoutée"}/>
      )}
      <Footer/>
    </div>
  )
}

export default ProductDetails
