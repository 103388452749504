import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../assets/logo.png"

const Footer = () => {
  return (
    <div className='footer'>
      <div className="footerContainer">
        <img style={{marginTop:"5px"}} src={logo} alt="" />
        <div className='footerInfos'>
            <div className='footerContainerInfos'>
            <div>
            <h3>Tel: +212 660296606</h3>
            <h3>Email: support@cozima.ma</h3>
            <h3>Adresse: Souk Olad Mimoun - Nador</h3>
            </div>
            <div className='navigationFooter'>
                <h3>Navigation:</h3>
                <Link style={{textDecoration:"none"}} to="/"><p>Home page</p></Link>
                <Link style={{textDecoration:"none"}} to="/cart"><p>Panier</p></Link>
                <Link style={{textDecoration:"none"}} to="/about"><p>About us</p></Link>
                <Link style={{textDecoration:"none"}} to="/contact"><p>Contact page</p></Link>
            </div>
            </div>
            <br />
            <center>
                <p className='copy'>Copyright © 2023 Elegant Themes ®</p>
            </center>
        </div>
      </div>
    </div>
  )
}

export default Footer
