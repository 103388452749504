import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import { ContextPageProvider, useCartBadge } from './ContextPage';

import Nav from './Nav';
import Home from './Components/Home';
import ProductDetails from './Components/ProductDetails';
import Cart from './Components/Cart';
import Admin from './Components/Admin';
import Product1 from './Components/Product1';
import Product2 from './Components/Product2';
import Products3 from './Components/Products3';
import Footer from './Components/Footer';
import Contact from './Components/Contact';
import Product4 from './Components/Product4';
import Product5 from './Components/Product5';
import Product6 from './Components/Product6';



function App() {

  return ( 
<ContextPageProvider>
<Router> 
  <div style={{paddingBottom:"60px"}}>
  <Nav/>
  </div>
<Routes> 
    <Route exact path='/' element={< Home />}></Route>  
    <Route path='/productDetails/:productType/:search' element={< ProductDetails />}></Route>  
    <Route path='/cart' element={< Cart />}></Route>  
    <Route path='/admin' element={< Admin />}></Route>  
    <Route path='/product1' element={< Product1 />}></Route>  
    <Route path='/product2' element={< Product2 />}></Route>  
    <Route path='/product3' element={< Products3/>}></Route>  

    <Route path='/product4' element={< Product4/>}></Route>  
    <Route path='/product5' element={< Product5/>}></Route>  
    <Route path='/product6' element={< Product6/>}></Route>  

    <Route path='/contact' element={< Contact/>}></Route>  

</Routes>

</Router>
</ ContextPageProvider>
      
  );
}

export default App;
