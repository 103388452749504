import React, { useEffect, useState } from 'react'
import home from "./Home.module.css"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import img1 from "../assets/img1.jpg"
import img2 from "../assets/img2.jpg"
import img3 from "../assets/img3.jpg"
import p1 from "../assets/p1.jpg"
import p2 from "../assets/p2.jpg"
import p3 from "../assets/p3.jpg"

import { Link, useNavigate } from 'react-router-dom';
import { useCartBadge } from '../ContextPage';
import Message from '../Messages/Message';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import db from '../Firebase';
import Footer from './Footer';



const Home = () => {
  const {cart,setCart,bannerC,setBannerC,banner2C,setBanner2C,productsC,setProductsC,pack1C,setPack1C,pack2C,setPack2C,carouselC,setCarouselC,carousel2C,setCarousel2C,products2HomeC,setProducts2HomeC} = useCartBadge();
  const [message,setMessage] = useState(false)
  const [products,setProducts] = useState([]);
  const [products2,setProducts2] = useState([]);
  const [carousel,setCarousel] = useState([]);
  const [carousel2,setCarousel2] = useState([]);
  const [bannerProducts,setBannerProducts] = useState([]);
  const [bannerProducts2,setBannerProducts2] = useState([]);
  const [pack1,setPack1] = useState([]);
  const [pack2,setPack2] = useState([]);



  const addDetailsInfos =async(name,description,oldPrice,price,discount,img,id)=>{
    const details = {
      name:name,
      description:description,
      oldPrice:oldPrice,
      price:price,
      discount:discount,
      img:img,
      id:id,
    }
    localStorage.setItem("details",JSON.stringify(details))
  }

  const addDetailsInfos2 =async(name,description,oldPrice,price,discount,img,id)=>{
    const details = {
      name:name,
      description:description,
      oldPrice:oldPrice,
      price:price,
      discount:discount,
      img:img,
      id:id,
    }
    localStorage.setItem("details",JSON.stringify(details))
  }

  const addToCart =async(name,description,price,img,id)=>{
    const cartItems = {
        name:name,
        description:description,
        price:price,
        total:price,
        quantity:1,
        img:img,
        id:id
    }

    const cart = await JSON.parse(localStorage.getItem("cozimaCart"));
    if (cart != null) {
        let newCart = [];
        newCart.push(...cart,cartItems);
        localStorage.setItem("cozimaCart",JSON.stringify(newCart));
    }else{
        localStorage.setItem("cozimaCart",JSON.stringify([cartItems]));
    }
    setCart(prev => prev + 1);
    setMessage(true);
}

useEffect(()=>{
  if (message) {
      
setTimeout(() => {
  setMessage(false);
}, 1500);
  }
},[message]);

const getProducts=async()=>{
  const querySnapshot = await getDocs(collection(db, "products"));
  const array = [];
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  array.push(doc.data());
  setProducts(array)
});
}

const getProducts2=async()=>{
  const querySnapshot = await getDocs(collection(db, "products2Home"));
  const array = [];
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  array.push(doc.data());
  setProducts2(array)
});
}

const getProductsBanner=async()=>{
  const querySnapshot = await getDocs(collection(db, "banners"));
  const array = [];
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  array.push(doc.data());
  setBannerProducts(array)
});
}

const getProductsBanner2=async()=>{
  const querySnapshot = await getDocs(collection(db, "banners2"));
  const array = [];
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  array.push(doc.data());
  setBannerProducts2(array)
});
}

const getPacks=async()=>{
  const docRef = doc(db, "packs", "pack1");
  const docSnap = await getDoc(docRef);
  const docRef2 = doc(db, "packs", "pack2");
  const docSnap2 = await getDoc(docRef2);

if (docSnap.exists()) {
  setPack1(docSnap.data())
} else {
  console.log("No such document!");
}
if (docSnap2.exists()) {
  setPack2(docSnap2.data())
} else {
  console.log("No such document!");
}
}

const getCarousel=async()=>{
  const querySnapshot = await getDocs(collection(db, "carousel"));
  const array =[];
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  array.push(doc.data());
});
console.log(array)
setCarousel(array);
  
}

const getCarousel2=async()=>{
  const querySnapshot = await getDocs(collection(db, "carousel2"));
  const array =[];
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  array.push(doc.data());
});
console.log(array)
setCarousel2(array);

}




useEffect(()=>{
  if (productsC.length < 1) {
    getProducts();
  }
},[]);

useEffect(()=>{
  if (products2HomeC.length < 1) {
    getProducts2();
  }
},[]);

useEffect(()=>{
  if (bannerC.length < 1) {
    getProductsBanner();
  }
},[]);

useEffect(()=>{
  if (banner2C.length < 1) {
    getProductsBanner2();
  }
},[]);

useEffect(()=>{
  if (pack1C.length < 1) {
      getPacks();
  }
  if (pack2C.length < 1) {
    getPacks();
}
},[])

useEffect(()=>{
  if (carouselC.length < 1) {
    getCarousel();
  }
},[])

useEffect(()=>{
  if (carousel2C.length < 1) {
    getCarousel2();
  }
},[])



useEffect(()=>{
  if (productsC.length < 1) {
    setProductsC(products);
  }
},[products]);

useEffect(()=>{
  if (products2HomeC.length < 1) {
    setProducts2HomeC(products2);
  }
},[products2]);

useEffect(()=>{
  if (bannerC.length < 1) {
    setBannerC(bannerProducts);
  }
},[bannerProducts]);

useEffect(()=>{
  if (banner2C.length < 1) {
    setBanner2C(bannerProducts2);
  }
},[bannerProducts2]);

useEffect(()=>{
  if (pack1C.length < 1) {
    setPack1C(pack1);
  }
},[pack1]);

useEffect(()=>{
  if (pack2C.length < 1) {
    setPack2C(pack2);
  }
},[pack2]);

useEffect(()=>{
  if (carouselC.length < 1) {
    setCarouselC(carousel);
  }
},[carousel]);

useEffect(()=>{
  if (carousel2C.length < 1) {
    setCarousel2C(carousel2);
  }
},[carousel2]);

const navigate = useNavigate();

const toProducts=(index)=>{
  if (index == 0) {
    navigate("/product1")
  }else if(index == 1){
    navigate("/product2")
  }else{
    navigate("/product3")
  }
}

const toProducts2=(index)=>{
  if (index == 0) {
    navigate("/product4")
  }else if(index == 1){
    navigate("/product5")
  }else{
    navigate("/product6")
  }
}
  return (
    <div className={home.home}>

      <div className={home.homeBanner}>

      </div>

      <center>
      <div className={home.firstBanner}>
        {bannerC?.map((items,index) => {return(
          <div onClick={()=> toProducts(index)} style={{backgroundImage: `url(${items.imgUrl})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPosition:"center",position:"relative",cursor:"pointer"}} className={home.image}>
          <div className={home.shadow}>
          <p>{items.title}</p>
          </div>
          </div>
        )})}
      </div>
      </center>

      <center>
      <div className={home.firstBanner}>
        {banner2C?.map((items,index) => {return(
          <div onClick={()=> toProducts2(index)} style={{backgroundImage: `url(${items.imgUrl})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPosition:"center",position:"relative",cursor:"pointer"}} className={home.image}>
          <div className={home.shadow}>
          <p>{items.title}</p>
          </div>
          </div>
        )})}
      </div>
      </center>

      <div className={home.secondBanner}>
      <CarouselProvider
        naturalSlideWidth={100} 
        naturalSlideHeight={20}
        visibleSlides={8}
        totalSlides={8}
        infinite={true} 

      >
     <center style={{position:"relative"}}>
     <Slider className={home.slide}>
          <Slide  index={0}>
            <div className={home.slideBox}>
              <img src={carouselC[0]?.imgUrl} alt="" />
            </div>
          </Slide>
          <Slide  index={1}>
          <div className={home.slideBox}>
          <img src={carouselC[1]?.imgUrl} alt="" />

          </div>
          </Slide>
          <Slide  index={2}>
            <div className={home.slideBox}>
            <img src={carouselC[2]?.imgUrl} alt="" />

          </div>
          </Slide>
          <Slide  index={3}>
            <div className={home.slideBox}>
            <img src={carouselC[3]?.imgUrl} alt="" />

          </div>
          </Slide>
          <Slide index={4}>
            <div className={home.slideBox}>
            <img src={carouselC[4]?.imgUrl} alt="" />

          </div>
          </Slide>
          <Slide index={5}>
            <div className={home.slideBox}>
            <img src={carouselC[5]?.imgUrl} alt="" />

          </div>
          </Slide>
          <Slide index={6}>
            <div className={home.slideBox}>
            <img src={carouselC[6]?.imgUrl} alt="" />

          </div>
          </Slide>
          <Slide index={7}>
            <div className={home.slideBox}>
            <img src={carouselC[7]?.imgUrl} alt="" />

          </div>
          </Slide> 
        </Slider>
        
     </center>
      </CarouselProvider>
      </div>


      <div className={home.secondBanner2}>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={20}
        visibleSlides={4}
        totalSlides={8}
        infinite={true} 

      >
     <center style={{position:"relative"}}>
     <Slider className={home.slide}>
          <Slide  index={0}>
            <div className={home.slideBox}>
              <img src={carouselC[0]?.imgUrl} alt="" />
            </div>
          </Slide>
          <Slide  index={1}>
          <div className={home.slideBox}>
          <img src={carouselC[1]?.imgUrl} alt="" />

          </div>
          </Slide>
          <Slide  index={2}>
            <div className={home.slideBox}>
            <img src={carouselC[2]?.imgUrl} alt="" />

          </div>
          </Slide>
          <Slide  index={3}>
            <div className={home.slideBox}>
            <img src={carouselC[3]?.imgUrl} alt="" />

          </div>
          </Slide>
          <Slide index={4}>
            <div className={home.slideBox}>
            <img src={carouselC[4]?.imgUrl} alt="" />

          </div>
          </Slide>
          <Slide index={5}>
            <div className={home.slideBox}>
            <img src={carouselC[5]?.imgUrl} alt="" />

          </div>
          </Slide>
          <Slide index={6}>
            <div className={home.slideBox}>
            <img src={carouselC[6]?.imgUrl} alt="" />

          </div>
          </Slide>
          <Slide index={7}>
            <div className={home.slideBox}>
            <img src={carouselC[7]?.imgUrl} alt="" />

          </div>
          </Slide>
        </Slider>
        <ButtonBack className={home.but1}>{"<"}</ButtonBack>
        <ButtonNext className={home.but2}>{">"}</ButtonNext>
     </center>
      </CarouselProvider>
      </div>



      <center style={{marginTop:"20px"}}>
        <h1 className={home.promoTitle}>تخفيض %40</h1>
      </center>



      <div className={home.products}>
        {productsC?.map((item,index)=>{
          return(
            <div className={home.product}>
              <Link onClick={()=> addDetailsInfos(item?.title,item?.desc,item?.price,item?.price2,item?.discount,item?.imgUrl,item?.id)} style={{textDecoration:"none"}} to={`/productDetails/products/${item.id}`}>
              <img src={item?.imgUrl} alt="" />
              <div className={home.discount}><p>{item?.discount}%</p></div>
              <div className={home.infos}>
              <h4 className={home.name}>{item?.title}</h4>
              <p className={home.desc}>{item?.desc}</p>
              <div className={home.priceSection}>
              <p className={home.oldPrice}>{item?.price} DH</p>
              <p className={home.price}>{item?.price2} DH</p>
              </div>
              </div>
              </Link>
              <button className={home.button} onClick={()=> {addToCart(item?.title,item?.desc,item?.price2,item?.imgUrl,item?.id); navigate("/cart")} }><p style={{color:"white",fontSize:"10px",top:"50px"}} className={home.oldPrice}>{item?.price} DH</p>Acheter<p style={{color:"white",fontSize:"10px"}} className={home.price}>{item?.price2} DH</p></button>
              <button onClick={()=> addToCart(item?.title,item?.desc,item?.price2,item?.imgUrl,item?.id)} ><p style={{color:"white",fontSize:"10px"}} className={home.oldPrice}>{item?.price} DH</p>Ajouter au panier<p style={{color:"white",fontSize:"10px"}} className={home.price}>{item?.price2} DH</p></button>
            </div>
          );
        })}
      </div>

      <div className={home.bottomPackProducts}>
        <div className={home.packProduct}>
          <center><p className={home.packTitle}> {pack1C?.title} </p></center>
          <div className={home.packImages}>
            <img src={pack1C?.imgUrl} alt="" /> 
            <img src={pack1C?.imgUrl2} alt="" />
            <img src={pack1C?.imgUrl3} alt="" />
          </div>
         <center>
         <div className={home.packPrice}>
          <div className={home.pricing}> 
          <h1 className={home.oldPrice}>{pack1C?.price}DH</h1>
          <h1 className={home.price}>{pack1C?.price2}DH</h1>
          </div>
          <button onClick={()=> addToCart(pack1C?.title,"",pack1C?.price,pack1C?.imgUrl,pack1C?.id)}>Buy now</button>
        </div>
         </center>
        </div>

      </div>

      <div className={home.thirdBanner}>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={20}
        visibleSlides={5}
        totalSlides={carousel2C?.length}
        infinite={true} 

      >
     <center style={{position:"relative"}}>
     <Slider className={home.slide}>
          {carousel2C[0] && (
            <Slide  index={0}>
            <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[0].id}`}>
            <div className={home.slideBox}>
              <img src={carousel2C[0]?.imgUrl} alt="" />
              <p className={home.oldPrice}>{carousel2C[0]?.price}DH</p>
              <p className={home.newPrice}>{carousel2C[0]?.price2}DH</p>
            </div>
            </Link>
          </Slide>
          )}
          {carousel2C[1] && (
            <Slide  index={1}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[1].id}`}>
              <div className={home.slideBox}>
            <img src={carousel2C[1]?.imgUrl} alt="" />
            <p className={home.oldPrice}>{carousel2C[1]?.price}DH</p>
                <p className={home.newPrice}>{carousel2C[1]?.price2}DH</p>
            </div>
              </Link>
            </Slide>
          )}
          {carousel2C[2] && (
            <Slide  index={2}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[2].id}`}>
              <div className={home.slideBox}>
            <img src={carousel2C[2]?.imgUrl} alt="" />
            <p className={home.oldPrice}>{carousel2C[2]?.price}DH</p>
              <p className={home.newPrice}>{carousel2C[2]?.price2}DH</p>
          </div>
              </Link>
            
          </Slide>
          )}
          {carousel2C[3] && (
            <Slide  index={3}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[3].id}`}>
              <div className={home.slideBox}>
                <img src={carousel2C[3]?.imgUrl} alt="" />
                <p className={home.oldPrice}>{carousel2C[3]?.price}DH</p>
                <p className={home.newPrice}>{carousel2C[3]?.price2}DH</p>
              </div>
              </Link>
            </Slide>
          )}
          {carousel2C[4] && (
            <Slide index={4}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[4].id}`}>
              <div className={home.slideBox}>
                <img src={carousel2C[4]?.imgUrl} alt="" />
                <p className={home.oldPrice}>{carousel2C[4]?.price}DH</p>
                <p className={home.newPrice}>{carousel2C[4]?.price2}DH</p>
              </div>
              </Link>
            </Slide>
          )}
          {carousel2C[5] && (
            <Slide index={5}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[5].id}`}>
              <div className={home.slideBox}>
                <img src={carousel2C[5]?.imgUrl} alt="" />
                <p className={home.oldPrice}>{carousel2C[5]?.price}DH</p>
                <p className={home.newPrice}>{carousel2C[5]?.price2}DH</p>
              </div>
              </Link>
            </Slide>
          )}
          {carousel2C[6] && (
            <Slide index={6}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[6].id}`}>
              <div className={home.slideBox}>
              <img src={carousel2C[6]?.imgUrl} alt="" />
              <p className={home.oldPrice}>{carousel2C[6]?.price}DH</p>
              <p className={home.newPrice}>{carousel2C[6]?.price2}DH</p>
            </div>
              </Link>
          </Slide>
          )}
          {carousel2C[7] && (
            <Slide index={7}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[7].id}`}>
              <div className={home.slideBox}>
              <img src={carousel2C[7]?.imgUrl} alt="" />
              <p className={home.oldPrice}>{carousel2C[7]?.price}DH</p>
              <p className={home.newPrice}>{carousel2C[7]?.price2}DH</p>
            </div>
              </Link>
          </Slide>
          )}
          {carousel2C[8] && (
            <Slide index={8}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[8].id}`}>
              <div className={home.slideBox}>
              <img src={carousel2C[8]?.imgUrl} alt="" />
              <p className={home.oldPrice}>{carousel2C[8]?.price}DH</p>
              <p className={home.newPrice}>{carousel2C[8]?.price2}DH</p>
            </div>
              </Link>
          </Slide>
          )}
          {carousel2C[9] && (
            <Slide index={9}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[9].id}`}>
              <div className={home.slideBox}>
                <img src={carousel2C[9]?.imgUrl} alt="" />
                <p className={home.oldPrice}>{carousel2C[9]?.price}DH</p>
                <p className={home.newPrice}>{carousel2C[9]?.price2}DH</p>
              </div>
              </Link>
            </Slide>
          )}
        </Slider>
        <ButtonBack className={home.but1}>{"<"}</ButtonBack>
        <ButtonNext className={home.but2}>{">"}</ButtonNext>
     </center>
      </CarouselProvider>
      </div>

      <div className={home.thirdBanner3}>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={20}
        visibleSlides={3}
        totalSlides={carousel2C?.length}
        infinite={true} 

      >
     <center style={{position:"relative"}}>
     <Slider className={home.slide}>
          {carousel2C[0] && (
            <Slide  index={0}>
            <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[0].id}`}>
            <div className={home.slideBox}>
              <img src={carousel2C[0]?.imgUrl} alt="" />
              <p className={home.oldPrice}>{carousel2C[0]?.price}DH</p>
              <p className={home.newPrice}>{carousel2C[0]?.price2}DH</p>
            </div>
            </Link>
          </Slide>
          )}
          {carousel2C[1] && (
            <Slide  index={1}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[1].id}`}>
              <div className={home.slideBox}>
            <img src={carousel2C[1]?.imgUrl} alt="" />
            <p className={home.oldPrice}>{carousel2C[1]?.price}DH</p>
                <p className={home.newPrice}>{carousel2C[1]?.price2}DH</p>
            </div>
              </Link>
            </Slide>
          )}
          {carousel2C[2] && (
            <Slide  index={2}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[2].id}`}>
              <div className={home.slideBox}>
            <img src={carousel2C[2]?.imgUrl} alt="" />
            <p className={home.oldPrice}>{carousel2C[2]?.price}DH</p>
              <p className={home.newPrice}>{carousel2C[2]?.price2}DH</p>
          </div>
              </Link>
            
          </Slide>
          )}
          {carousel2C[3] && (
            <Slide  index={3}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[3].id}`}>
              <div className={home.slideBox}>
                <img src={carousel2C[3]?.imgUrl} alt="" />
                <p className={home.oldPrice}>{carousel2C[3]?.price}DH</p>
                <p className={home.newPrice}>{carousel2C[3]?.price2}DH</p>
              </div>
              </Link>
            </Slide>
          )}
          {carousel2C[4] && (
            <Slide index={4}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[4].id}`}>
              <div className={home.slideBox}>
                <img src={carousel2C[4]?.imgUrl} alt="" />
                <p className={home.oldPrice}>{carousel2C[4]?.price}DH</p>
                <p className={home.newPrice}>{carousel2C[4]?.price2}DH</p>
              </div>
              </Link>
            </Slide>
          )}
          {carousel2C[5] && (
            <Slide index={5}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[5].id}`}>
              <div className={home.slideBox}>
                <img src={carousel2C[5]?.imgUrl} alt="" />
                <p className={home.oldPrice}>{carousel2C[5]?.price}DH</p>
                <p className={home.newPrice}>{carousel2C[5]?.price2}DH</p>
              </div>
              </Link>
            </Slide>
          )}
          {carousel2C[6] && (
            <Slide index={6}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[6].id}`}>
              <div className={home.slideBox}>
              <img src={carousel2C[6]?.imgUrl} alt="" />
              <p className={home.oldPrice}>{carousel2C[6]?.price}DH</p>
              <p className={home.newPrice}>{carousel2C[6]?.price2}DH</p>
            </div>
              </Link>
          </Slide>
          )}
          {carousel2C[7] && (
            <Slide index={7}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[7].id}`}>
              <div className={home.slideBox}>
              <img src={carousel2C[7]?.imgUrl} alt="" />
              <p className={home.oldPrice}>{carousel2C[7]?.price}DH</p>
              <p className={home.newPrice}>{carousel2C[7]?.price2}DH</p>
            </div>
              </Link>
          </Slide>
          )}
          {carousel2C[8] && (
            <Slide index={8}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[8].id}`}>
              <div className={home.slideBox}>
              <img src={carousel2C[8]?.imgUrl} alt="" />
              <p className={home.oldPrice}>{carousel2C[8]?.price}DH</p>
              <p className={home.newPrice}>{carousel2C[8]?.price2}DH</p>
            </div>
              </Link>
          </Slide>
          )}
          {carousel2C[9] && (
            <Slide index={9}>
              <Link style={{textDecoration:"none"}} to={`/productDetails/carousel2/${carousel2C[9].id}`}>
              <div className={home.slideBox}>
                <img src={carousel2C[9]?.imgUrl} alt="" />
                <p className={home.oldPrice}>{carousel2C[9]?.price}DH</p>
                <p className={home.newPrice}>{carousel2C[9]?.price2}DH</p>
              </div>
              </Link>
            </Slide>
          )}
        </Slider>
        <ButtonBack className={home.but1}>{"<"}</ButtonBack>
        <ButtonNext className={home.but2}>{">"}</ButtonNext>
     </center>
      </CarouselProvider>
      </div>

      <div className={home.products}>
        {products2HomeC?.map((item,index)=>{
          return(
            <div className={home.product}>
              <Link onClick={()=> addDetailsInfos2(item?.title,item?.desc,item?.price,item?.price2,item?.discount,item?.imgUrl,item?.id)} style={{textDecoration:"none"}} to={`/productDetails/products2Home/${item.id}`}>
              <img src={item?.imgUrl} alt="" />
              <div className={home.discount}><p>{item?.discount}%</p></div>
              <div className={home.infos}>
              <h4 className={home.name}>{item?.title}</h4>
              <p className={home.desc}>{item?.desc}</p>
              <div className={home.priceSection}>
              <p className={home.oldPrice}>{item?.price} DH</p>
              <p className={home.price}>{item?.price2} DH</p>
              </div>
              </div>
              </Link>
              <button className={home.button} onClick={()=> {addToCart(item?.title,item?.desc,item?.price2,item?.imgUrl,item?.id); navigate("/cart")} }><p style={{color:"white",fontSize:"10px",top:"50px"}} className={home.oldPrice}>{item?.price} DH</p>Acheter<p style={{color:"white",fontSize:"10px"}} className={home.price}>{item?.price2} DH</p></button>
              <button onClick={()=> addToCart(item?.title,item?.desc,item?.price2,item?.imgUrl,item?.id)} ><p style={{color:"white",fontSize:"10px"}} className={home.oldPrice}>{item?.price} DH</p>Ajouter au panier<p style={{color:"white",fontSize:"10px"}} className={home.price}>{item?.price2} DH</p></button>
            </div>
          );
        })}
      </div>
 







      <div className={home.bottomPackProducts}>
        <div className={home.packProduct}>
          <center><p className={home.packTitle}>{pack2C?.title}</p></center>
          <div className={home.packImages}>
            <img src={pack2C?.imgUrl} alt="" />
            <img src={pack2C?.imgUrl2} alt="" />
            <img src={pack2C?.imgUrl3} alt="" />
          </div>
         <center>
         <div className={home.packPrice}>
          <div className={home.pricing}>
          <h1 className={home.oldPrice}>{pack2C?.price}DH</h1>
          <h1 className={home.price}>{pack2C?.price2}DH</h1>
          </div>
          <button onClick={()=> addToCart(pack2C?.title,"",pack2C?.price,pack2C?.imgUrl,pack2C?.id)}>Buy now</button>
        </div>
         </center>
        </div>

      </div>
      {message && (
        <Message message={"Produit bien ajoutée"}/>
      )}
<Footer/>
    </div>
  )
}

export default Home
