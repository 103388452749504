import React, { useEffect, useId, useRef, useState } from 'react'
import admin from "./Admin.module.css";
import { collection, deleteDoc, doc, getDoc, getDocs, increment, updateDoc } from 'firebase/firestore';
import db from '../Firebase';
import arrow from "../assets/arrow.png"
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { setDoc } from "firebase/firestore"; 
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Link, useNavigate } from 'react-router-dom';
import { useCartBadge } from '../ContextPage';
import home from "./Home.module.css"
import edit from "../assets/edit.png"
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { yoid } from 'yoid';
import logo from "../assets/logo.png"
import logo2 from "../assets/logo2.png"




const Commandes =({item,index})=>{


  return(
    <tr key={index}>
      <td className={admin.lwl}>{index + 1}</td>
      <td>{item.name}</td>
      <td className={admin.talt}>{item.price}</td>
      <td className={admin.talt}>{item.quantity} Piece(s)</td>
      <td className={admin.talt}>{item.total} DH</td>
    </tr>
  )
}

  

const Messages=()=>{
  const [messages,setMessages]= useState([]);

  const getMessages=async()=>{
    const array =[];
    const querySnapshot = await getDocs(collection(db, "messages"));
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  array.push(doc.data());
  console.log(doc.data())
});
setMessages(array);
  }

  const deleteMessage=async(id)=>{
    await deleteDoc(doc(db, "messages", id));
    setMessages([]);
    getMessages();
  }

  useEffect(()=>{
    if (messages.length < 1) {
      getMessages()
    }
  },[])
  return(
    <div style={{width:"100%"}}>
      {messages?.map((item,index)=> { return(
        <div style={{width:"90%",backgroundColor:"white",textAlign:"left",paddingLeft:"5px",marginTop:"10px",paddingTop:"2px",paddingBottom:"2px",border:"1px solid rgb(183, 183, 183)",position:"relative"}}>
        <div onClick={()=> deleteMessage(item?.id)} style={{position:"absolute",top:"5px",right:"10px",backgroundColor:"red",color:"white",borderRadius:"100px",width:"23px",height:"20px",display:"flex",justifyContent:"center",alignItems:"flex-start",paddingBottom:"5px",cursor:"pointer"}}><p >x</p></div>
         
          <p style={{marginBottom:"2px"}}><h4>Nom:</h4>{item?.name}</p>
          <p style={{marginBottom:"2px"}}><h4>Email:</h4>{item?.email}</p>
          <p><h4>Message:</h4>{item?.message}</p>
        </div>
      )})} 
    </div>
  );
}

const CommandeItems = ({item,index,setOpen2,setDeleteName,setDeleteId,total,setTotal})=>{
    const [view,setView] = useState(false);
    const [commande,setCommande] = useState([]);
    const [style,setStyle] = useState("none");

    useEffect(()=>{
        setCommande(item.commande);
    },[])
    const componentRef = useRef();


    const handlePrint=()=>{
      setStyle("inline");
      
      setTimeout(()=>{
          handlePrint2();
    
      },500);
    }
    const id = yoid();

    const date = new Date();
    const day = date.getDay();
    const month = date.getMonth();
    const year = date.getFullYear();
    const Dcm =()=>{
      return(
          <div ref={componentRef} className="pdf">
            

            <center>
              <img style={{width:"200px",marginTop:"30px",marginBottom:"20px"}} src={logo2} alt="" />
            </center>
                    <div className={admin.ShowCommande}>
                    <p style={{fontWeight:"bold",marginBottom:"10px",marginTop:"10px"}}>Facture:</p>
                    </div>
   

                <div className={admin.facture}>
              <div className={admin.factureContainer}>
                <div className={admin.factureBox}>

                  <div className={admin.factureHeader}>
                    <div className={admin.rightHeader}>
                      <div className={admin.rightHeaderContainer}>
                      <label>Nom:</label>
                      <input className={admin.nom} value={item.name} type="text" name="" id="" />
                      <label>Code:</label>
                      <input value={"C-"+id} className={admin.code} type="text" name="" id="" />
                      <label>Adresse:</label>
                      <input className={admin.adresse} value={item.adresse} type="text" name="" id="" />
                        <label>Tel:</label>
                        <input className={admin.telephone} value={item.telephone} type="text" name="" id="" />
                      </div>
                    </div>
                    <div>
                      <h1>Bon de livraison</h1>
                      <p>Realisé le: {day + "/" + month +"/" + year}</p>
                    </div>
                  </div>
                  <table className={admin.table}>
                    <thead>
                      <tr>
                      <th>Identifiant</th>
                      <th>Nom produit</th>
                      <th>Prix</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                    
        {commande.map((item,index)=>{
          return(
            <Commandes item={item} index={index}/>
        )})}
                        <tr>
                        <td colSpan={4}>Total TTC a payer</td>
                        <td>{item?.total} DH</td>
                      </tr>
                    </tbody>
                  </table>
                  <center>
                    <div className={admin.factureFooter}>
                      <p>Adresse: Souk Olad Mimoun - Nador - Maroc</p>
                      <p>Email: support@cozima.ma - Site web: https://cozima.ma</p>
                      <p>Tel: 212 06 60 29 66 06</p>
                    </div>
                  </center>
                </div>
              </div>
            </div>
                
          </div>
      )
    }





    const handlePrint2 = useReactToPrint({
      content :() => componentRef.current,
      documentTitle:"estimation",
      onAfterPrint: ()=> setStyle("none")
    })



    

    return(
        <>
        <div className={admin.hotelInformations} key={index}>
                <div style={{display:"flex",alignItems:"center"}}>
                <div onClick={()=> setView(!view)} style={{width:"20px",height:"20px",backgroundColor:"#0B5EDBF5",borderRadius:"100px",marginLeft:"10px",cursor:"pointer",justifyContent:"center",alignItems:"center",display:"flex"}}><img style={{width:"80%"}} src={arrow} alt="" /></div>
                <p>{item.name}</p>
                </div>
                <button onClick={()=> {setOpen2(true);setDeleteName(item.name);setDeleteId(item.id)}} className={admin.delete}>
                    <span>x</span>
                </button>
            </div>

            {view && (
                <div className={admin.details}>
                    <div className={admin.infos}>
                        <li><span style={{fontWeight:"bold",marginRight:"5px"}}>Nom: </span> {item.name}</li>
                    </div>
                    <div className={admin.infos}>
                        <li><span style={{fontWeight:"bold",marginRight:"5px"}}>Phone: </span> {item.telephone}</li>
                    </div>
                    <div className={admin.infos}>
                        <li><span style={{fontWeight:"bold",marginRight:"5px"}}>Adresse: </span> {item.adresse}</li>
                    </div>
                    <div className={admin.infos}>
                        <li><span style={{fontWeight:"bold",marginRight:"5px"}}>Total: </span> {item.total} DH</li>
                    </div>
                    <div className={admin.ShowCommande}>
                    <p style={{fontWeight:"bold",marginBottom:"10px",marginTop:"10px"}}>Commandes:</p>
                    <table>
      <thead>
        <tr>
          <th className={admin.lwl}>Identifiant</th>
          <th>Nom</th>
          <th className={admin.talt}>prix</th>
          <th className={admin.talt}>quantity</th>
          <th className={admin.talt}>total</th>

        </tr>
      </thead>
      <tbody>
        {commande.map((itemC,indexC)=> (
          <tr key={indexC}>
            <td className={admin.lwl}>{indexC + 1}</td>
            <td>{itemC.name}</td>
            <td className={admin.talt}>{itemC.price}</td>
            <td className={admin.talt}>{itemC.quantity} Piece(s)</td>
            <td className={admin.talt}>{itemC.total} DH</td>
          </tr>
        ))}
      </tbody>
    </table>
      </div>
      <div>
                <button style={{width:"150px",height:"28px",marginLeft:"12px",marginTop:"5px",cursor:"pointer"}} onClick={handlePrint}>Imprimer</button>
                </div>    
                </div>
            )}


           

        <div style={{display:"none"}}>
      <Dcm/>
      </div>
        </>
    )
}


const GestionProduits =()=>{
  const [title,setTitle] = useState(null);
  const [desc,setDesc] = useState(null);
  const [price,setPrice] = useState(null);
  const [price2,setPrice2] = useState(null);
  const [discount,setDiscount] = useState(0);
  const [file,setFile] = useState(null)
  const [inputKey,setInputKeyValue] = useState(0);
  const [modele,setModele] = useState("");
  const [poids,setPoids] = useState("");
  const [couleur,setColeur] = useState("");
  const [materiel,setMateriel] = useState("");
  const [caracter,setCaracter] = useState("");

  const [title2,setTitle2] = useState(null);
  const [desc2,setDesc2] = useState(null);
  const [priceHome2,setPriceHome2] = useState(null);
  const [price2Home2,setPrice2Home2] = useState(null);
  const [discount2,setDiscount2] = useState(0);
  const [file2,setFile2] = useState(null)

  const {cart,setCart,bannerC,setBannerC,banner2C,setBanner2C,productsC,setProductsC,pack1C,setPack1C,pack2C,setPack2C,carouselC,setCarouselC,carousel2C,setCarousel2C,products2HomeC,setProducts2HomeC,products1C,setProducts1C,products2C,setProducts2C,products3C,setProducts3C,products4C,setProducts4C,products5C,setProducts5C,products6C,setProducts6C} = useCartBadge();

  const [titleUpdate,setTitleUpdate] = useState(null);
  const [descriptionUpdate,setDiscriptionUpdate] = useState(null);
  const [priceUpdate,setPriceUodate] = useState(null);
  const [oldPriceUpdate,setOldPriceUpdate] = useState(null);
  const [fileUpdate,setFileUpdate] = useState(null);
  const [fileUpdate2,setFileUpdate2] = useState(null);
  const [fileUpdate3,setFileUpdate3] = useState(null);

  const [updateId,setUpdateId] = useState(null);
  const [updateCollection,setUpdateCollection] = useState(null);
  const [discountUpdate,setDiscountUpdate] = useState(null);
  const [updateDocumentIndex,setUpdateDocumentIndex] = useState(null);
  const [showUpdate,setShowUpdate] = useState(false);
  const [inputs,setInputs] = useState(true)
  const [inputs2,setInputs2] = useState(true)
  const [inputs3,setInputs3] = useState(true)
  const [inputs4,setInputs4] = useState(true)
  const [inputs5,setInputs5] = useState(true)
  const [inputs6,setInputs6] = useState(true)
  const [inputs66,setInputs66] = useState(true)
  const [inputs666,setInputs666] = useState(true)

  const [inputs7,setInputs7] = useState(true)
  const [inputs8,setInputs8] = useState(true)
  const [inputs9,setInputs9] = useState(true)
  const [inputs10,setInputs10] = useState(true)
  const [inputs11,setInputs11] = useState(true)

  const [productsPage1,setProductsPage1] = useState([]);
  const [productsPage2,setProductsPage2] = useState([]);
  const [productsPage3,setProductsPage3] = useState([]);

  const [productsPage4,setProductsPage4] = useState([]);
  const [productsPage5,setProductsPage5] = useState([]);
  const [productsPage6,setProductsPage6] = useState([]);


  const getProductsPage1=async()=>{
    const querySnapshot = await getDocs(collection(db, "products1"));
    const array = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    array.push(doc.data());
    setProductsPage1(array)
  });
  }


  useEffect(()=>{
    if (products1C.length < 1) {
      getProductsPage1();
    }
  },[products1C]);

  useEffect(()=>{
    if (products1C.length < 1) {
      setProducts1C(productsPage1);
    }
  },[productsPage1]);


  const getProductsPage2=async()=>{
    const querySnapshot = await getDocs(collection(db, "products2"));
    const array = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    array.push(doc.data());
    setProductsPage2(array)
  });
  }


  useEffect(()=>{
    if (products2C.length < 1) {
      getProductsPage2();
    }
  },[products2C]);

  useEffect(()=>{
    if (products2C.length < 1) {
      setProducts2C(productsPage2);
    }
  },[productsPage2]);

  const getProductsPage3=async()=>{
    const querySnapshot = await getDocs(collection(db, "products3"));
    const array = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    array.push(doc.data());
    setProductsPage3(array)
  });
  }


  useEffect(()=>{
    if (products3C.length < 1) {
      getProductsPage3();
    }
  },[products3C]);

  useEffect(()=>{
    if (products3C.length < 1) {
      setProducts3C(productsPage3);
    }
  },[productsPage3]);



  const getProductsPage4=async()=>{
    const querySnapshot = await getDocs(collection(db, "products4"));
    const array = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    array.push(doc.data());
    setProductsPage4(array)
  });
  }

  useEffect(()=>{
    if (products4C.length < 1) {
      getProductsPage4();
    }
  },[products4C]);

  useEffect(()=>{
    if (products4C.length < 1) {
      setProducts4C(productsPage4);
    }
  },[productsPage4]);

  const getProductsPage5=async()=>{
    const querySnapshot = await getDocs(collection(db, "products5"));
    const array = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    array.push(doc.data());
    setProductsPage5(array)
  });
  }

  useEffect(()=>{
    if (products5C.length < 1) {
      getProductsPage5();
    }
  },[products5C]);

  useEffect(()=>{
    if (products5C.length < 1) {
      setProducts5C(productsPage5);
    }
  },[productsPage5]);

  const getProductsPage6=async()=>{
    const querySnapshot = await getDocs(collection(db, "products6"));
    const array = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    array.push(doc.data());
    setProductsPage6(array)
  });
  }

  useEffect(()=>{
    if (products6C.length < 1) {
      getProductsPage6();
    }
  },[products5C]);

  useEffect(()=>{
    if (products6C.length < 1) {
      setProducts6C(productsPage6);
    }
  },[productsPage6]);





const [page,setPage] = useState("home");




  
  const updateProduct = async(e)=>{
    const washingtonRef = doc(db, updateCollection, updateId);
    alert(updateCollection)
    const storage = getStorage(); 

    e.preventDefault();
    const storageRef = ref(storage, 'products/' + updateId);
    const uploadTask = uploadBytesResumable(storageRef, fileUpdate);

    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {
        const washingtonRef = doc(db, updateCollection, updateId);

        await updateDoc(washingtonRef, {
          title: titleUpdate,
          desc: descriptionUpdate,
          price: price,
          id:updateId,
          price2:price2,
          discount:discount,
          modele:modele,
          poids:poids,
          couleur:couleur,
          materiel:materiel,
          caracter:caracter,
          imgUrl: downloadURL,
        });
        console.log('File available at', downloadURL);
      });
      setTitleUpdate("");
      setDiscriptionUpdate("");
      setPrice("");
      setDiscount("0");
      setPrice2("");
      setModele("");
      setPoids("");
      setColeur("");
      setMateriel("");
      setCaracter("");
      setInputKeyValue(inputKey == 0 ? 1 : 0);
      setProductsPage1([])
      setProductsPage2([])
      setProductsPage3([])
      setProductsPage4([])
      setProductsPage5([])
      setProductsPage6([])
      setProducts1C([]);
      setProducts2C([]);
      setProducts3C([]);
      setProducts4C([]);
      setProducts5C([]);
      setProducts6C([]);
    }
  );
  }


  const updatePack = async(e)=>{
    const washingtonRef = doc(db, updateCollection, updateId);
    alert(updateCollection)
    const storage = getStorage(); 

    e.preventDefault();
    const storageRef = ref(storage, 'products/' + updateId);
    const uploadTask = uploadBytesResumable(storageRef, fileUpdate);
    const uploadTask2 = uploadBytesResumable(storageRef, fileUpdate2);
    const uploadTask3 = uploadBytesResumable(storageRef, fileUpdate3);


    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {
        const washingtonRef = doc(db, updateCollection, updateId);

        await updateDoc(washingtonRef, {
          title: titleUpdate,
          price: price,
          id:updateId,
          price2:price2,
          discount:discount,
          imgUrl: downloadURL,
        });
        console.log('File available at', downloadURL);
      })
      setTitleUpdate("");
      setPrice("");
      setDiscount("0");
      setPrice2("");
    }
  );

  uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {
        const washingtonRef = doc(db, updateCollection, updateId);

        await updateDoc(washingtonRef, {
          imgUrl2: downloadURL,
        });
        console.log('File available at', downloadURL);
      })
    }
  );
  uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {
        const washingtonRef = doc(db, updateCollection, updateId);

        await updateDoc(washingtonRef, {
          imgUrl3: downloadURL,
        });
        console.log('File available at', downloadURL);
      })
      setInputKeyValue(inputKey == 0 ? 1 : 0);
    }
  );
  }

  useEffect(()=>{
    if (showUpdate) {
      if (updateCollection === "banners") {
        setInputs(true)
        setInputs2(false)
        setInputs3(false)
        setInputs4(false)
        setInputs5(false)
        setInputs6(true)
        setInputs66(false)
        setInputs666(false)
        setInputs7(false)
        setInputs8(false)
        setInputs9(false)
        setInputs10(false)
        setInputs11(false)

        setTitleUpdate(bannerC[updateDocumentIndex].title);
      }else if (updateCollection === "banners2") {
        setInputs(true)
        setInputs2(false)
        setInputs3(false)
        setInputs4(false)
        setInputs5(false)
        setInputs6(true)
        setInputs66(false)
        setInputs666(false)
        setInputs7(false)
        setInputs8(false)
        setInputs9(false)
        setInputs10(false)
        setInputs11(false)

        setTitleUpdate(banner2C[updateDocumentIndex].title);
      }else if (updateCollection === "carousel") {
        setInputs(false)
        setInputs2(false)
        setInputs3(false)
        setInputs4(false)
        setInputs5(false)
        setInputs6(true)
        setInputs66(false)
        setInputs666(false)
        setInputs7(false)
        setInputs8(false)
        setInputs9(false)
        setInputs10(false)
        setInputs11(false)

      }else if(updateCollection === "products"){
        setInputs(true)
        setInputs2(true)
        setInputs3(true)
        setInputs4(true)
        setInputs5(true)
        setInputs6(true)
        setInputs66(false)
        setInputs666(false)
        setInputs7(true)
        setInputs8(true)
        setInputs9(true)
        setInputs10(true)
        setInputs11(true)
        setTitleUpdate(productsC[updateDocumentIndex].title);
        setDiscriptionUpdate(productsC[updateDocumentIndex].desc);
        setDiscount(productsC[updateDocumentIndex].discount);
        setPrice(productsC[updateDocumentIndex].price);
        setModele(productsC[updateDocumentIndex].modele);
        setColeur(productsC[updateDocumentIndex].couleur);
        setPoids(productsC[updateDocumentIndex].poids);
        setMateriel(productsC[updateDocumentIndex].materiel);
        setCaracter(productsC[updateDocumentIndex].caracter);

      }else if(updateCollection === "products2Home"){
        setInputs(true)
        setInputs2(true)
        setInputs3(true)
        setInputs4(true)
        setInputs5(true)
        setInputs6(true)
        setInputs66(false)
        setInputs666(false)
        setInputs7(true)
        setInputs8(true)
        setInputs9(true)
        setInputs10(true)
        setInputs11(true)
        setTitleUpdate(products2HomeC[updateDocumentIndex].title);
        setDiscriptionUpdate(products2HomeC[updateDocumentIndex].desc);
        setDiscount(products2HomeC[updateDocumentIndex].discount);
        setPrice(products2HomeC[updateDocumentIndex].price);
        setModele(products2HomeC[updateDocumentIndex].modele);
        setColeur(products2HomeC[updateDocumentIndex].couleur);
        setPoids(products2HomeC[updateDocumentIndex].poids);
        setMateriel(products2HomeC[updateDocumentIndex].materiel);
        setCaracter(products2HomeC[updateDocumentIndex].caracter);

      }else if(updateCollection === "carousel2"){
        setInputs(false)
        setInputs2(true)
        setInputs3(true)
        setInputs4(true)
        setInputs5(true)
        setInputs6(true)
        setInputs66(false)
        setInputs666(false)
        setInputs7(true)
        setInputs8(true)
        setInputs9(true)
        setInputs10(true)
        setInputs11(true)
        setTitleUpdate(carousel2C[updateDocumentIndex]?.title);
        setDiscriptionUpdate(carousel2C[updateDocumentIndex]?.desc);
        setDiscount(carousel2C[updateDocumentIndex]?.discount);
        setModele(carousel2C[updateDocumentIndex]?.modele);
        setColeur(carousel2C[updateDocumentIndex]?.couleur);
        setPoids(carousel2C[updateDocumentIndex]?.poids);
        setMateriel(carousel2C[updateDocumentIndex]?.materiel);
        setCaracter(carousel2C[updateDocumentIndex]?.caracter);
        setPrice(carousel2C[updateDocumentIndex]?.price);
        setPrice2(carousel2C[updateDocumentIndex]?.price2);
      }else if(updateCollection === "packs" && updateId === "pack1"){
        setInputs(true)
        setInputs2(false)
        setInputs3(true)
        setInputs4(false)
        setInputs5(true)
        setInputs6(true)
        setInputs66(true)
        setInputs666(true)
        setInputs7(false)
        setInputs8(false)
        setInputs9(false)
        setInputs10(false)
        setInputs11(false)
        setTitleUpdate(pack1C?.title);
        setPrice(pack1C?.price);
        setPrice2(pack1C?.price2);
    }else if (updateCollection === "packs" && updateId === "pack2") {
      setInputs(true)
        setInputs2(false)
        setInputs3(true)
        setInputs4(false)
        setInputs5(true)
        setInputs6(true)
        setInputs66(true)
        setInputs666(true)
        setInputs7(false)
        setInputs8(false)
        setInputs9(false)
        setInputs10(false)
        setInputs11(false)
        setTitleUpdate(pack2C?.title);
        setPrice(pack2C?.price);
        setPrice2(pack2C?.price2);
    }else if (updateCollection === "products1"){
      setInputs(true)
        setInputs2(true)
        setInputs3(true)
        setInputs4(true)
        setInputs5(true)
        setInputs6(true)
        setInputs66(false)
        setInputs666(false)
        setInputs7(true)
        setInputs8(true)
        setInputs9(true)
        setInputs10(true)
        setInputs11(true)
        setTitleUpdate(products1C[updateDocumentIndex]?.title);
        setDiscriptionUpdate(products1C[updateDocumentIndex]?.desc);
        setDiscount(products1C[updateDocumentIndex]?.discount);
        setModele(products1C[updateDocumentIndex]?.modele);
        setColeur(products1C[updateDocumentIndex]?.couleur);
        setPoids(products1C[updateDocumentIndex]?.poids);
        setMateriel(products1C[updateDocumentIndex]?.materiel);
        setCaracter(products1C[updateDocumentIndex]?.caracter);
        setPrice(products1C[updateDocumentIndex]?.price);
        setPrice2(products1C[updateDocumentIndex]?.price2);
    }else if (updateCollection === "products2"){
      setInputs(true)
        setInputs2(true)
        setInputs3(true)
        setInputs4(true)
        setInputs5(true)
        setInputs6(true)
        setInputs66(false)
        setInputs666(false)
        setInputs7(true)
        setInputs8(true)
        setInputs9(true)
        setInputs10(true)
        setInputs11(true)
        setTitleUpdate(products2C[updateDocumentIndex]?.title);
        setDiscriptionUpdate(products2C[updateDocumentIndex]?.desc);
        setDiscount(products2C[updateDocumentIndex]?.discount);
        setModele(products2C[updateDocumentIndex]?.modele);
        setColeur(products2C[updateDocumentIndex]?.couleur);
        setPoids(products2C[updateDocumentIndex]?.poids);
        setMateriel(products2C[updateDocumentIndex]?.materiel);
        setCaracter(products2C[updateDocumentIndex]?.caracter);
        setPrice(products2C[updateDocumentIndex]?.price);
        setPrice2(products2C[updateDocumentIndex]?.price2);
    }else if (updateCollection === "products3"){
      setInputs(true)
        setInputs2(true)
        setInputs3(true)
        setInputs4(true)
        setInputs5(true)
        setInputs6(true)
        setInputs66(false)
        setInputs666(false)
        setInputs7(true)
        setInputs8(true)
        setInputs9(true)
        setInputs10(true)
        setInputs11(true)
        setTitleUpdate(products3C[updateDocumentIndex]?.title);
        setDiscriptionUpdate(products3C[updateDocumentIndex]?.desc);
        setDiscount(products3C[updateDocumentIndex]?.discount);
        setModele(products3C[updateDocumentIndex]?.modele);
        setColeur(products3C[updateDocumentIndex]?.couleur);
        setPoids(products3C[updateDocumentIndex]?.poids);
        setMateriel(products3C[updateDocumentIndex]?.materiel);
        setCaracter(products3C[updateDocumentIndex]?.caracter);
        setPrice(products3C[updateDocumentIndex]?.price);
        setPrice2(products3C[updateDocumentIndex]?.price2);
    }else if (updateCollection === "products4"){
      setInputs(true)
        setInputs2(true)
        setInputs3(true)
        setInputs4(true)
        setInputs5(true)
        setInputs6(true)
        setInputs66(false)
        setInputs666(false)
        setInputs7(true)
        setInputs8(true)
        setInputs9(true)
        setInputs10(true)
        setInputs11(true)
        setTitleUpdate(products4C[updateDocumentIndex]?.title);
        setDiscriptionUpdate(products4C[updateDocumentIndex]?.desc);
        setDiscount(products4C[updateDocumentIndex]?.discount);
        setModele(products4C[updateDocumentIndex]?.modele);
        setColeur(products4C[updateDocumentIndex]?.couleur);
        setPoids(products4C[updateDocumentIndex]?.poids);
        setMateriel(products4C[updateDocumentIndex]?.materiel);
        setCaracter(products4C[updateDocumentIndex]?.caracter);
        setPrice(products4C[updateDocumentIndex]?.price);
        setPrice2(products4C[updateDocumentIndex]?.price2);
    }else if (updateCollection === "products5"){
      setInputs(true)
        setInputs2(true)
        setInputs3(true)
        setInputs4(true)
        setInputs5(true)
        setInputs6(true)
        setInputs66(false)
        setInputs666(false)
        setInputs7(true)
        setInputs8(true)
        setInputs9(true)
        setInputs10(true)
        setInputs11(true)
        setTitleUpdate(products5C[updateDocumentIndex]?.title);
        setDiscriptionUpdate(products5C[updateDocumentIndex]?.desc);
        setDiscount(products5C[updateDocumentIndex]?.discount);
        setModele(products5C[updateDocumentIndex]?.modele);
        setColeur(products5C[updateDocumentIndex]?.couleur);
        setPoids(products5C[updateDocumentIndex]?.poids);
        setMateriel(products5C[updateDocumentIndex]?.materiel);
        setCaracter(products5C[updateDocumentIndex]?.caracter);
        setPrice(products5C[updateDocumentIndex]?.price);
        setPrice2(products5C[updateDocumentIndex]?.price2);
    }else if (updateCollection === "products6"){
      setInputs(true)
        setInputs2(true)
        setInputs3(true)
        setInputs4(true)
        setInputs5(true)
        setInputs6(true)
        setInputs66(false)
        setInputs666(false)
        setInputs7(true)
        setInputs8(true)
        setInputs9(true)
        setInputs10(true)
        setInputs11(true)
        setTitleUpdate(products6C[updateDocumentIndex]?.title);
        setDiscriptionUpdate(products6C[updateDocumentIndex]?.desc);
        setDiscount(products6C[updateDocumentIndex]?.discount);
        setModele(products6C[updateDocumentIndex]?.modele);
        setColeur(products6C[updateDocumentIndex]?.couleur);
        setPoids(products6C[updateDocumentIndex]?.poids);
        setMateriel(products6C[updateDocumentIndex]?.materiel);
        setCaracter(products6C[updateDocumentIndex]?.caracter);
        setPrice(products6C[updateDocumentIndex]?.price);
        setPrice2(products6C[updateDocumentIndex]?.price2);
    }
  }
  },[showUpdate])

  const [bTitle,setBtitle] = useState(null)
  const [bTitle2,setBtitle2] = useState(null)
  const [bTitle3,setBtitle3] = useState(null)
  const [bfile,setBfile] = useState(null)
  const [bfile2,setBfile2] = useState(null)
  const [bfile3,setBfile3] = useState(null)

  const [bTitle4,setBtitle4] = useState(null)
  const [bTitle5,setBtitle5] = useState(null)
  const [bTitle6,setBtitle6] = useState(null)
  const [bfile4,setBfile4] = useState(null)
  const [bfile5,setBfile5] = useState(null)
  const [bfile6,setBfile6] = useState(null)

  const [prod1Title,setProd1Title] = useState(null)
  const [prod1Price,setProd1Price] = useState(null)
  const [prod1Desc,setProd1Desc] = useState(null)
  const [prod1Price2,setProd1Price2] = useState(null)
  const [prod1Discount,setProd1Discount] = useState(null)
  const [prod1File,setProd1File] = useState(null)

  const [prod2Title,setProd2Title] = useState(null)
  const [prod2Price,setProd2Price] = useState(null)
  const [prod2Desc,setProd2Desc] = useState(null)
  const [prod2Price2,setProd2Price2] = useState(null)
  const [prod2Discount,setProd2Discount] = useState(null)
  const [prod2File,setProd2File] = useState(null)

  const [prod3Title,setProd3Title] = useState(null)
  const [prod3Price,setProd3Price] = useState(null)
  const [prod3Desc,setProd3Desc] = useState(null)
  const [prod3Price2,setProd3Price2] = useState(null)
  const [prod3Discount,setProd3Discount] = useState(null)
  const [prod3File,setProd3File] = useState(null)

  const [prod4Title,setProd4Title] = useState(null)
  const [prod4Price,setProd4Price] = useState(null)
  const [prod4Desc,setProd4Desc] = useState(null)
  const [prod4Price2,setProd4Price2] = useState(null)
  const [prod4Discount,setProd4Discount] = useState(null)
  const [prod4File,setProd4File] = useState(null)


  const [prod5Title,setProd5Title] = useState(null)
  const [prod5Price,setProd5Price] = useState(null)
  const [prod5Desc,setProd5Desc] = useState(null)
  const [prod5Price2,setProd5Price2] = useState(null)
  const [prod5Discount,setProd5Discount] = useState(null)
  const [prod5File,setProd5File] = useState(null)


  const [prod6Title,setProd6Title] = useState(null)
  const [prod6Price,setProd6Price] = useState(null)
  const [prod6Desc,setProd6Desc] = useState(null)
  const [prod6Price2,setProd6Price2] = useState(null)
  const [prod6Discount,setProd6Discount] = useState(null)
  const [prod6File,setProd6File] = useState(null)
  useEffect(()=>{
    if (discount != 0) {
      setPrice2(price - (price * discount/100));
    }else{
      setPrice2(price)
    }
  },[discount]);

  useEffect(()=>{
    if (discount2 != 0) {
      setPrice2Home2(priceHome2 - (priceHome2 * discount2/100));
    }else{
      setPrice2Home2(priceHome2)
    }
  },[discount2]);

  useEffect(()=>{
    if (prod1Discount != 0) {
      setProd1Price2(prod1Price - (prod1Price * prod1Discount/100));
    }else{
      setProd1Price2(prod1Price)
    }
  },[prod1Discount]);

  useEffect(()=>{
    if (prod2Discount != 0) {
      setProd2Price2(prod2Price - (prod2Price * prod2Discount/100));
    }else{
      setProd2Price2(prod2Price)
    }
  },[prod2Discount]);

  useEffect(()=>{
    if (prod3Discount != 0) {
      setProd3Price2(prod3Price - (prod3Price * prod3Discount/100));
    }else{
      setProd3Price2(prod3Price)
    }
  },[prod3Discount]);


  useEffect(()=>{
    if (prod4Discount != 0) {
      setProd4Price2(prod4Price - (prod4Price * prod4Discount/100));
    }else{
      setProd4Price2(prod4Price)
    }
  },[prod4Discount]);

  useEffect(()=>{
    if (prod5Discount != 0) {
      setProd5Price2(prod5Price - (prod5Price * prod5Discount/100));
    }else{
      setProd5Price2(prod5Price)
    }
  },[prod5Discount]);


  useEffect(()=>{
    if (prod6Discount != 0) {
      setProd6Price2(prod6Price - (prod6Price * prod6Discount/100));
    }else{
      setProd6Price2(prod6Price)
    }
  },[prod6Discount]);


  

  const addProduct1 = async(e)=>{
    e.preventDefault();

    let number = 0;
    const docRef = doc(db, "admin", "products1Number");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      number = docSnap.data().value;
    } else {
      console.log("No such document!");
    }
    if (number === 16) {
      alert("Votre rubrique de produits 1 est saturé");
      return;
    }else{
      console.log("doz");
    }

    const storage = getStorage();
    const id = uuidv4();
    const storageRef = ref(storage, 'products/' + id);
    const uploadTask = uploadBytesResumable(storageRef, prod1File);
    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "products1", id), {
          title: title,
          desc: desc,
          price: price,
          id:id,
          price2:price2,
          discount:discount,
          imgUrl: downloadURL,
          modele:modele,
          poids:poids,
          couleur:couleur,
          materiel:materiel,
          caracter:caracter
        });
        const washingtonRef = doc(db, "admin", "products1Number");
        await updateDoc(washingtonRef, {
          value: increment(1)
        });
        console.log('File available at', downloadURL);
      });
      setTitle("");
      setDesc("");
      setPrice("");
      setDiscount("0");
      setPrice2("");
      setInputKeyValue(inputKey == 0 ? 1 : 0);
      setModele("");
      setPoids("");
      setColeur("");
      setMateriel("");
      setCaracter("");
    }
  );
  }

  const addProduct2 = async(e)=>{
    e.preventDefault();

    let number = 0;
    const docRef = doc(db, "admin", "products2Number");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      number = docSnap.data().value;
    } else {
      console.log("No such document!");
    }
    if (number === 16) {
      alert("Votre rubrique de produits 2 est saturé");
      return;
    }else{
      console.log("doz");
    }

    const storage = getStorage();
    const id = uuidv4();
    const storageRef = ref(storage, 'products/' + id);
    const uploadTask = uploadBytesResumable(storageRef, prod2File);
    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "products2", id), {
          title: title,
          desc: desc,
          price: price,
          id:id,
          price2:price2,
          discount:discount,
          imgUrl: downloadURL,
          modele:modele,
          poids:poids,
          couleur:couleur,
          materiel:materiel,
          caracter:caracter
        });
        const washingtonRef = doc(db, "admin", "products2Number");
        await updateDoc(washingtonRef, {
          value: increment(1)
        });
        console.log('File available at', downloadURL);
      });
      setTitle("");
      setDesc("");
      setPrice("");
      setDiscount("0");
      setPrice2("");
      setInputKeyValue(inputKey == 0 ? 1 : 0);
      setModele("");
      setPoids("");
      setColeur("");
      setMateriel("");
      setCaracter("");
    }
  );
  }

  const addProduct3 = async(e)=>{
    e.preventDefault();

    let number = 0;
    const docRef = doc(db, "admin", "products3Number");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      number = docSnap.data().value;
    } else {
      console.log("No such document!");
    }
    if (number === 16) {
      alert("Votre rubrique de produits 3 est saturé");
      return;
    }else{
      console.log("doz");
    }

    const storage = getStorage();
    const id = uuidv4();
    const storageRef = ref(storage, 'products/' + id);
    const uploadTask = uploadBytesResumable(storageRef, prod3File);
    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "products3", id), {
          title: title,
          desc: desc,
          price: price,
          id:id,
          price2:price2,
          discount:discount,
          imgUrl: downloadURL,
          modele:modele,
          poids:poids,
          couleur:couleur,
          materiel:materiel,
          caracter:caracter
        });
        const washingtonRef = doc(db, "admin", "products3Number");
        await updateDoc(washingtonRef, {
          value: increment(1)
        });
        console.log('File available at', downloadURL);
      });
      setTitle("");
      setDesc("");
      setPrice("");
      setDiscount("0");
      setPrice2("");
      setInputKeyValue(inputKey == 0 ? 1 : 0);
      setModele("");
      setPoids("");
      setColeur("");
      setMateriel("");
      setCaracter("");
    }
  );
  }
  const addProduct4 = async(e)=>{
    e.preventDefault();

    let number = 0;
    const docRef = doc(db, "admin", "products4Number");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      number = docSnap.data().value;
    } else {
      console.log("No such document!");
    }
    if (number === 16) {
      alert("Votre rubrique de produits 4 est saturé");
      return;
    }else{
      console.log("doz");
    }

    const storage = getStorage();
    const id = uuidv4();
    const storageRef = ref(storage, 'products/' + id);
    const uploadTask = uploadBytesResumable(storageRef, prod4File);
    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "products4", id), {
          title: title,
          desc: desc,
          price: price,
          id:id,
          price2:price2,
          discount:discount,
          imgUrl: downloadURL,
          modele:modele,
          poids:poids,
          couleur:couleur,
          materiel:materiel,
          caracter:caracter
        });
        const washingtonRef = doc(db, "admin", "products4Number");
        await updateDoc(washingtonRef, {
          value: increment(1)
        });
        console.log('File available at', downloadURL);
      });
      setTitle("");
      setDesc("");
      setPrice("");
      setDiscount("0");
      setPrice2("");
      setInputKeyValue(inputKey == 0 ? 1 : 0);
      setModele("");
      setPoids("");
      setColeur("");
      setMateriel("");
      setCaracter("");
    }
  );
  }

  const addProduct5 = async(e)=>{
    e.preventDefault();

    let number = 0;
    const docRef = doc(db, "admin", "products5Number");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      number = docSnap.data().value;
    } else {
      console.log("No such document!");
    }
    if (number === 16) {
      alert("Votre rubrique de produits 5 est saturé");
      return;
    }else{
      console.log("doz");
    }

    const storage = getStorage();
    const id = uuidv4();
    const storageRef = ref(storage, 'products/' + id);
    const uploadTask = uploadBytesResumable(storageRef, prod5File);
    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "products5", id), {
          title: title,
          desc: desc,
          price: price,
          id:id,
          price2:price2,
          discount:discount,
          imgUrl: downloadURL,
          modele:modele,
          poids:poids,
          couleur:couleur,
          materiel:materiel,
          caracter:caracter
        });
        const washingtonRef = doc(db, "admin", "products5Number");
        await updateDoc(washingtonRef, {
          value: increment(1)
        });
        console.log('File available at', downloadURL);
      });
      setTitle("");
      setDesc("");
      setPrice("");
      setDiscount("0");
      setPrice2("");
      setInputKeyValue(inputKey == 0 ? 1 : 0);
      setModele("");
      setPoids("");
      setColeur("");
      setMateriel("");
      setCaracter("");
    }
  );
  }


  const addProduct6 = async(e)=>{
    e.preventDefault();

    let number = 0;
    const docRef = doc(db, "admin", "products6Number");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      number = docSnap.data().value;
    } else {
      console.log("No such document!");
    }
    if (number === 16) {
      alert("Votre rubrique de produits 6 est saturé");
      return;
    }else{
      console.log("doz");
    }

    const storage = getStorage();
    const id = uuidv4();
    const storageRef = ref(storage, 'products/' + id);
    const uploadTask = uploadBytesResumable(storageRef, prod6File);
    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "products6", id), {
          title: title,
          desc: desc,
          price: price,
          id:id,
          price2:price2,
          discount:discount,
          imgUrl: downloadURL,
          modele:modele,
          poids:poids,
          couleur:couleur,
          materiel:materiel,
          caracter:caracter
        });
        const washingtonRef = doc(db, "admin", "products6Number");
        await updateDoc(washingtonRef, {
          value: increment(1)
        });
        console.log('File available at', downloadURL);
      });
      setTitle("");
      setDesc("");
      setPrice("");
      setDiscount("0");
      setPrice2("");
      setInputKeyValue(inputKey == 0 ? 1 : 0);
      setModele("");
      setPoids("");
      setColeur("");
      setMateriel("");
      setCaracter("");
    }
  );
  }


  const addProductHome2 = async(e)=>{
    e.preventDefault();

    let number = 0;
    const docRef = doc(db, "admin", "productsHome2Number");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      number = docSnap.data().value;
    } else {
      console.log("No such document!");
    }
    if (number === 12) { 
      alert("Votre rubrique de produit 2 (Home) est saturé");
      return;
    }else{
      console.log("doz");
    }

    const storage = getStorage();
    const id = uuidv4();
    const storageRef = ref(storage, 'products/' + id);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "products2Home", id), {
          title: title,
          desc: desc,
          price: price,
          id:id,
          price2:price2,
          discount:discount,
          imgUrl: downloadURL,
          modele:modele,
          poids:poids,
          couleur:couleur,
          materiel:materiel,
          caracter:caracter
        });
        const washingtonRef = doc(db, "admin", "productsHome2Number");
        await updateDoc(washingtonRef, {
          value: increment(1)
        });
        console.log('File available at', downloadURL);
      });
      setTitle("");
      setDesc("");
      setPrice("");
      setDiscount("0");
      setPrice2("");
      setInputKeyValue(inputKey == 0 ? 1 : 0);
      setModele("");
      setPoids("");
      setColeur("");
      setMateriel("");
      setCaracter("");
    }
  );
  }



  const addProduct = async(e)=>{
    e.preventDefault();

    let number = 0;
    const docRef = doc(db, "admin", "productsNumber");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      number = docSnap.data().value;
    } else {
      console.log("No such document!");
    }
    if (number === 12) {
      alert("Votre rubrique de produit (Home) est saturé");
      return;
    }else{
      console.log("doz");
    }

    const storage = getStorage();
    const id = uuidv4();
    const storageRef = ref(storage, 'products/' + id);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "products", id), {
          title: title,
          desc: desc,
          price: price,
          id:id,
          price2:price2,
          discount:discount,
          modele:modele,
          poids:poids,
          couleur:couleur,
          materiel:materiel,
          caracter:caracter,
          imgUrl: downloadURL,
        });
        const washingtonRef = doc(db, "admin", "productsNumber");
        await updateDoc(washingtonRef, {
          value: increment(1)
        });
        console.log('File available at', downloadURL);
      });
      setTitle("");
      setDesc("");
      setPrice("");
      setDiscount("0");
      setPrice2("");
      setFile("");
      setModele("");
      setPoids("");
      setColeur("");
      setMateriel("");
      setCaracter("");
      setInputKeyValue(inputKey == 0 ? 1 : 0);
    }
  );
  }

  const addProductBanner = async(e)=>{
    const storage = getStorage();

    e.preventDefault();
    const storageRef = ref(storage, 'products/' + "banner1");
    const uploadTask = uploadBytesResumable(storageRef, bfile);

    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "banners", "banner1"), {
          title: bTitle,
          id:"banner1",
          imgUrl: downloadURL,
        });
        console.log('File available at', downloadURL);
      });
      setBtitle("");
      setBfile("");
    }
  );
  }


  const addProductBanner2 = async(e)=>{
    const storage = getStorage();

    e.preventDefault();
    const storageRef = ref(storage, 'products/' + "banner2");
    const uploadTask = uploadBytesResumable(storageRef, bfile2);

    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "banners", "banner2"), {
          title: bTitle2,
          id:"banner2",
          imgUrl: downloadURL,
        });
        console.log('File available at', downloadURL);
      });
      setBtitle2("");
      setBfile2("");
    }
  );
  }

  const addProductBanner3 = async(e)=>{
    const storage = getStorage();

    e.preventDefault();
    const storageRef = ref(storage, 'products/' + "banner3");
    const uploadTask = uploadBytesResumable(storageRef, bfile3);

    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "banners", "banner3"), {
          title: bTitle3,
          id:"banner3",
          imgUrl: downloadURL,
        });
        console.log('File available at', downloadURL);
      });
      setBtitle3("");
      setBfile3("");
    }
  );
  }
  
  const addProductBanner4 = async(e)=>{
    const storage = getStorage();

    e.preventDefault();
    const storageRef = ref(storage, 'products/' + "banner4");
    const uploadTask = uploadBytesResumable(storageRef, bfile4);

    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "banners2", "banner4"), {
          title: bTitle4,
          id:"banner4",
          imgUrl: downloadURL,
        });
        console.log('File available at', downloadURL);
      });
      setBtitle4("");
      setBfile4("");
    }
  );
  }


  const addProductBanner5 = async(e)=>{
    const storage = getStorage();

    e.preventDefault();
    const storageRef = ref(storage, 'products/' + "banner5");
    const uploadTask = uploadBytesResumable(storageRef, bfile5);

    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "banners2", "banner5"), {
          title: bTitle5,
          id:"banner5",
          imgUrl: downloadURL,
        });
        console.log('File available at', downloadURL);
      });
      setBtitle5("");
      setBfile5("");
    }
  );
  }

  const addProductBanner6 = async(e)=>{
    const storage = getStorage();

    e.preventDefault();
    const storageRef = ref(storage, 'products/' + "banner6");
    const uploadTask = uploadBytesResumable(storageRef, bfile6);

    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "banners2", "banner6"), {
          title: bTitle6,
          id:"banner6",
          imgUrl: downloadURL,
        });
        console.log('File available at', downloadURL);
      });
      setBtitle6("");
      setBfile6("");
    }
  );
  }




  const [pTitle,setPtitle] = useState(null)
  const [pfile,setPfile] = useState(null)
  const [pfile2,setPfile2] = useState(null)
  const [pfile3,setPfile3] = useState(null)

  const [p2Title,setP2title] = useState(null)
  const [p2file,setP2file] = useState(null)
  const [p2file2,setP2file2] = useState(null)
  const [p2file3,setP2file3] = useState(null)

  const [pricePack,setPricePack] = useState();
  const [pricePack2,setPricePack2] = useState();
  const [oldPricePack,setOldPricePack] = useState();
  const [oldPricePack2,setOldPricePack2] = useState();
  const [discountPack,setDiscountPack] = useState(0);
  const [discountPack2,setDiscountPack2] = useState(0);


  useEffect(()=>{
    if (discountPack != 0) {
      setOldPricePack(pricePack - (pricePack * discountPack/100));
    }else{
      setOldPricePack(price)
    }
  },[discountPack]);

  useEffect(()=>{
    if (discountPack2 != 0) {
      setOldPricePack2(pricePack2 - (pricePack2 * discountPack2/100));
    }else{
      setOldPricePack2(price)
    }
  },[discountPack2]);

  const addPack = async(e)=>{
    const storage = getStorage();

    e.preventDefault();
    const storageRef = ref(storage, 'products/' + "pack1Img");
    const storageRef2 = ref(storage, 'products/' + "pack1Img2");
    const storageRef3 = ref(storage, 'products/' + "pack1Img3");

    const uploadTask = uploadBytesResumable(storageRef, pfile);
    const uploadTask2 = uploadBytesResumable(storageRef2, pfile2);
    const uploadTask3 = uploadBytesResumable(storageRef3, pfile3);

    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "packs", "pack1"), {
          title: pTitle,
          id:"pack1",
          imgUrl: downloadURL,
          price:pricePack,
          oldPrice:oldPricePack,
        });
        console.log('File available at', downloadURL);
      }).then(()=> {
        uploadTask2.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask2.snapshot.ref).then(async(downloadURL) => {

        const washingtonRef = doc(db, "packs", "pack1");
        await updateDoc(washingtonRef, {
          imgUrl2: downloadURL,
        });
        console.log('File available at', downloadURL);
      });

    }
  );
      }).finally(()=>{
        uploadTask3.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask3.snapshot.ref).then(async(downloadURL) => {

        const washingtonRef = doc(db, "packs", "pack1");
        await updateDoc(washingtonRef, {
          imgUrl3: downloadURL,
        });
        console.log('File available at', downloadURL);
      });

    },
  );
      });
      
    }
  );

      setPtitle("");
      setPfile("");
      setPfile2("");
      setPfile3("");
      setPricePack("");
      setOldPricePack("");
      setDiscountPack(0);
  }


  const addPack2 = async(e)=>{
    const storage = getStorage();

    e.preventDefault();
   
    const storageRef = ref(storage, 'products/' + "pack2Img");
    const storageRef2 = ref(storage, 'products/' + "pack2Img2");
    const storageRef3 = ref(storage, 'products/' + "pack2Img3");

    const uploadTask = uploadBytesResumable(storageRef, p2file);
    const uploadTask2 = uploadBytesResumable(storageRef2, p2file2);
    const uploadTask3 = uploadBytesResumable(storageRef3, p2file3);

    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        await setDoc(doc(db, "packs", "pack2"), {
          title: p2Title,
          id:"pack2",
          imgUrl: downloadURL,
          price:pricePack2,
          oldPrice:oldPricePack2,
        });
        console.log('File available at', downloadURL);
      }).then(()=> {
        uploadTask2.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask2.snapshot.ref).then(async(downloadURL) => {

        const washingtonRef = doc(db, "packs", "pack2");
        await updateDoc(washingtonRef, {
          imgUrl2: downloadURL,
        });
        console.log('File available at', downloadURL);
      });

    }
  );
      }).finally(()=>{
        uploadTask3.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask3.snapshot.ref).then(async(downloadURL) => {

        const washingtonRef = doc(db, "packs", "pack2");
        await updateDoc(washingtonRef, {
          imgUrl3: downloadURL,
        });
        console.log('File available at', downloadURL);
      });

    },
  );
      });
      
    }
  );

      setP2title("");
      setP2file("");
      setP2file2("");
      setP2file3("");
      setPricePack2("");
      setOldPricePack2("");
      setDiscountPack2(0);
  }



  const [image1,setImage1] = useState("");



  const [images1,setImages1] = useState("");
  const [images2,setImages2] = useState("");
  const [images3,setImages3] = useState("");
  const [images4,setImages4] = useState("");
  const [images5,setImages5] = useState("");
  const [images6,setImages6] = useState("");
  const [images7,setImages7] = useState("");
  const [images8,setImages8] = useState("");
  const [images9,setImages9] = useState("");
  const [images10,setImages10] = useState("");


  const handleImage =(e)=>{
    setImages1(e.target.files[0]);
  }
  

  const handleProdFile =(e)=>{
    setProd1File(e.target.files[0]);
  }
  const handleProd2File =(e)=>{
    setProd2File(e.target.files[0]);
  }
  const handleProd3File =(e)=>{
    setProd3File(e.target.files[0]);
  }

  const handleProd4File =(e)=>{
    setProd4File(e.target.files[0]);
  }
  const handleProd5File =(e)=>{
    setProd5File(e.target.files[0]);
  }
  const handleProd6File =(e)=>{
    setProd6File(e.target.files[0]);
  }


  const handleFile =(e)=>{
    setFile(e.target.files[0]);
  }
  const handleFile2 =(e)=>{
    setFile2(e.target.files[0]);
  }


  const handleFileb =(e)=>{
    setBfile(e.target.files[0]);
  }

  const handleFileb2 =(e)=>{
    setBfile2(e.target.files[0]);
  }
  const handleFileb3 =(e)=>{
    setBfile3(e.target.files[0]);
  }

  const handleFileb4 =(e)=>{
    setBfile4(e.target.files[0]);
  }

  const handleFileb5 =(e)=>{
    setBfile5(e.target.files[0]);
  }
  const handleFileb6 =(e)=>{
    setBfile6(e.target.files[0]);
  }

  useEffect(()=>{
    console.log(file)
  },[file])

  const handleFilePack =(e)=>{
    setPfile(e.target.files[0]);
  }
  const handleFile2Pack =(e)=>{
    setPfile2(e.target.files[0]);
  }
  const handleFile3Pack =(e)=>{
    setPfile3(e.target.files[0]);
  }

  const handleFilePack2 =(e)=>{
    setP2file(e.target.files[0]);
  }
  const handleFile2Pack2 =(e)=>{
    setP2file2(e.target.files[0]);
  }
  const handleFile3Pack2 =(e)=>{
    setP2file3(e.target.files[0]);
  }


  const handleFileImage=(e)=>{
    setImage1(e.target.files[0]);
  }

  const addImage1 =async(e)=>{
    let number = 0;
    const docRef = doc(db, "admin", "carouselNumber");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      number = docSnap.data().value;
    } else {
      console.log("No such document!");
    }
    if (number === 8) {
      alert("Votre rubrique de produits 1 est saturé");
      return;
    }else{
      console.log("doz");
    }
    const storage = getStorage();
    const id = uuidv4();
    const storageRef = ref(storage, 'products/' + id);
    const uploadTask = uploadBytesResumable(storageRef, image1);

    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

        const washingtonRef = doc(db, "carousel", id);
        await setDoc(doc(db, "carousel", id), {
          imgUrl:downloadURL,
          id:id,
        });
        const washingtonRef2 = doc(db, "admin", "carouselNumber");
        await updateDoc(washingtonRef2, {
          value: increment(1)
        });
        console.log('File available at', downloadURL);
      });
      setInputKeyValue(inputKey == 0 ? 1 : 0);
    }
  );
  }
 


  const addImages =async(e)=>{
    e.preventDefault();
    if (image1 != "") {
      addImage1();
    }
    else{
      console.log("madztch")
    }
  }



  const addCarousel2 =async(e)=>{
    let number = 0;
    const docRef = doc(db, "admin", "carousel2Number");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      number = docSnap.data().value;
    } else {
      console.log("No such document!");
    }
    if (number === 10) {
      alert("Votre rubrique du carousel 2 est saturé");
      return;
    }else{
      console.log("doz");
    }
    const storage = getStorage();
    const id = uuidv4();
    const storageRef = ref(storage, 'products/' + id);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {       
        await setDoc(doc(db,"carousel2", id), {
          title: title,
          desc: desc,
          price: price,
          id:id,
          price2:price2,
          discount:discount, 
          modele:modele,
          poids:poids,
          couleur:couleur,
          materiel:materiel,
          caracter:caracter,
          imgUrl: downloadURL,
        });
        console.log('File available at', downloadURL);
        const washingtonRef = doc(db, "admin", "carouselNumber");
        await updateDoc(washingtonRef, {
          value: increment(1)
        });
      });
      
      setTitle("");
      setDesc("");
      setPrice("");
      setDiscount("0");
      setPrice2("");
      setFile("");
      setModele("");
      setPoids("");
      setColeur("");
      setMateriel("");
      setCaracter("");
      setInputKeyValue(inputKey == 0 ? 1 : 0);
    }
  );
  }


  const addImages22 =async(e)=>{
    e.preventDefault();
    if (file != "") {
      addCarousel2()
    }
    else{
      console.log("madztch")
    }
  }
  const [view,setView] = useState(false);
  const [view2,setView2] = useState(false);
  const [view3,setView3] = useState(false);
  const [view4,setView4] = useState(false);
  const [view5,setView5] = useState(false);
  const [view6,setView6] = useState(false);
  const [view7,setView7] = useState(false);
  const [view8,setView8] = useState(false);
  const [view66,setView66] = useState(false);
  const [view77,setView77] = useState(false);
  const [view88,setView88] = useState(false);
  const [view9,setView9] = useState(false);


  useEffect(()=>{
    if (view == true) {
      setView2(false)
      setView3(false)
      setView4(false)
      setView5(false)
      setView6(false)
      setView7(false)
      setView8(false)
      setView9(false)
      setView66(false)
      setView77(false)
      setView88(false)

    }
  },[view])
  useEffect(()=>{
   if (view2 == true) {
      setView(false)
      setView3(false)
      setView4(false)
      setView5(false)
      setView6(false)
      setView7(false)
      setView8(false)
      setView9(false)
      setView66(false)
      setView77(false)
      setView88(false)

    }
  },[view2])
  useEffect(()=>{
    if (view3 == true) {
      setView2(false)
      setView(false)
      setView4(false)
      setView5(false)
      setView6(false)
      setView7(false)
      setView8(false)
      setView9(false)
      setView66(false)
      setView77(false)
      setView88(false)

    }
  },[view3])
  useEffect(()=>{
   if (view4 == true) {
      setView2(false)
      setView3(false)
      setView(false)
      setView5(false)
      setView6(false)
      setView7(false)
      setView8(false)
      setView9(false)
      setView66(false)
      setView77(false)
      setView88(false)

    }
  },[view4])
  useEffect(()=>{
    if (view5 == true) {
      setView2(false)
      setView3(false)
      setView4(false)
      setView(false)
      setView6(false)
      setView7(false)
      setView8(false)
      setView9(false)
      setView66(false)
      setView77(false)
      setView88(false)

    }
  },[view5])
  useEffect(()=>{
    if (view6 == true) {
      setView2(false)
      setView3(false)
      setView4(false)
      setView5(false)
      setView(false)
      setView7(false)
      setView8(false)
      setView9(false)
      setView66(false)
      setView77(false)
      setView88(false)

    }
  },[view6])
  useEffect(()=>{
    if (view7 == true) {
      setView2(false)
      setView3(false)
      setView4(false)
      setView5(false)
      setView6(false)
      setView(false)
      setView8(false)
      setView9(false)
      setView66(false)
      setView77(false)
      setView88(false)

    }
  },[view7])
  useEffect(()=>{
    if (view8 == true) {
      setView2(false)
      setView3(false)
      setView4(false)
      setView5(false)
      setView6(false)
      setView7(false)
      setView9(false)
      setView(false)
      setView66(false)
      setView77(false)
      setView88(false)


    }
  },[view8])
  useEffect(()=>{
    if (view66 == true) {
      setView2(false)
      setView3(false)
      setView4(false)
      setView5(false)
      setView6(false)
      setView7(false)
      setView8(false)
      setView9(false)
      setView(false)
      setView77(false)
      setView88(false)

    }
  },[view66])
  useEffect(()=>{
    if (view77 == true) {
      setView2(false)
      setView3(false)
      setView4(false)
      setView5(false)
      setView6(false)
      setView7(false)
      setView8(false)
      setView9(false)
      setView(false)
      setView66(false)
      setView88(false)

    }
  },[view77])

  
  useEffect(()=>{
    if (view88 == true) {
      setView2(false)
      setView3(false)
      setView4(false)
      setView5(false)
      setView6(false)
      setView7(false)
      setView8(false)
      setView9(false)
      setView(false)
      setView66(false)
      setView77(false)

    }
  },[view88])

  useEffect(()=>{
    if (view9 == true) {
      setView2(false)
      setView3(false)
      setView4(false)
      setView5(false)
      setView6(false)
      setView7(false)
      setView8(false)
      setView(false)
      setView66(false)
      setView77(false)
      setView88(false)

    }
  },[view9]);

  const [products,setProducts] = useState([]);
  const [products2,setProducts2] = useState([]);
  const [carousel,setCarousel] = useState([]);
  const [carousel2,setCarousel2] = useState([]);
  const [bannerProducts,setBannerProducts] = useState([]);
  const [bannerProducts2,setBannerProducts2] = useState([]);
  const [pack1,setPack1] = useState([]);
  const [pack2,setPack2] = useState([]);

  const getProducts=async()=>{
    const querySnapshot = await getDocs(collection(db, "products"));
    const array = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    array.push(doc.data());
    setProducts(array)
  });
  }
  
  const getProducts2=async()=>{
    const querySnapshot = await getDocs(collection(db, "products2Home"));
    const array = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    array.push(doc.data());
    setProducts2(array)
  });
  }
  
  const getProductsBanner=async()=>{
    const querySnapshot = await getDocs(collection(db, "banners"));
    const array = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    array.push(doc.data());
    setBannerProducts(array)
  });
  }
  const getProductsBanner2=async()=>{
    const querySnapshot = await getDocs(collection(db, "banners2"));
    const array = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    array.push(doc.data());
    setBannerProducts2(array)
  });
  }
  
  
  const getPacks=async()=>{
    const docRef = doc(db, "packs", "pack1");
    const docSnap = await getDoc(docRef);
    const docRef2 = doc(db, "packs", "pack2");
    const docSnap2 = await getDoc(docRef2);
  
  if (docSnap.exists()) {
    setPack1(docSnap.data())
  } else {
    console.log("No such document!");
  }
  if (docSnap2.exists()) {
    setPack2(docSnap2.data())
  } else {
    console.log("No such document!");
  }
  }
  
  const getCarousel=async()=>{
    const querySnapshot = await getDocs(collection(db, "carousel"));
    const array =[];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    array.push(doc.data());
  });
  console.log(array)
  setCarousel(array); 
  }
  
  const getCarousel2=async()=>{
    const querySnapshot = await getDocs(collection(db, "carousel2"));
    const array =[];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    array.push(doc.data());
  });
  console.log(array)
  setCarousel2(array); 
  }
  
  
  
  
  useEffect(()=>{
    if (productsC.length < 1) {
      getProducts();
    }
  },[]);
  
  useEffect(()=>{
    if (products2HomeC.length < 1) {
      getProducts2();
    }
  },[]);
  
  useEffect(()=>{
    if (bannerC.length < 1) {
      getProductsBanner();
    }
  },[]);

  useEffect(()=>{
    if (banner2C.length < 1) {
      getProductsBanner2();
    }
  },[]);
  
  useEffect(()=>{
    if (pack1C.length < 1) {
        getPacks();
    }
    if (pack2C.length < 1) {
      getPacks();
  }
  },[])
  
  useEffect(()=>{
    if (carouselC.length < 1) {
      getCarousel();
    }
  },[])
  
  useEffect(()=>{
    if (carousel2C.length < 1) {
      getCarousel2();
    }
  },[])
  
  
  
  useEffect(()=>{
    if (productsC.length < 1) {
      setProductsC(products);
    }
  },[products]);
  
  useEffect(()=>{
    if (products2HomeC.length < 1) {
      setProducts2HomeC(products2);
    }
  },[products2]);
  
  useEffect(()=>{
    if (bannerC.length < 1) {
      setBannerC(bannerProducts);
    }
  },[bannerProducts]);

  useEffect(()=>{
    if (banner2C.length < 1) {
      setBanner2C(bannerProducts2);
    }
  },[bannerProducts2]);
  
  useEffect(()=>{
    if (pack1C.length < 1) {
      setPack1C(pack1);
    }
  },[pack1]);
  
  useEffect(()=>{
    if (pack2C.length < 1) {
      setPack2C(pack2);
    }
  },[pack2]);
  
  useEffect(()=>{
    if (carouselC.length < 1) {
      setCarouselC(carousel);
    }
  },[carousel]);
  
  useEffect(()=>{
    if (carousel2C.length < 1) {
      setCarousel2C(carousel2);
    }
  },[carousel2]);

  const deleteProduct = async()=>{
    const storage = getStorage();
    await deleteDoc(doc(db, updateCollection, updateId));

// Create a reference to the file to delete
const desertRef = ref(storage, updateId);
// Delete the file
deleteObject(desertRef).then(() => {
  // File deleted successfully
}).catch((error) => {
  // Uh-oh, an error occurred!
});
let document = "";
if (updateCollection === "products") {
  document = "productsNumber"
}else if(updateCollection === "products2Home"){
  document = "productsHome2Number"
}else if(updateCollection === "carousel2"){
  document = "carousel2Number"
}else if(updateCollection === "products1"){
  document = "products1Number"
}else if(updateCollection === "products2"){
  document = "products2Number"
}else if(updateCollection === "products3"){
  document = "products3Number"
}else if(updateCollection === "products4"){
  document = "products4Number"
}else if(updateCollection === "products5"){
  document = "products5Number"
}else if(updateCollection === "products6"){
  document = "products6Number"
}
const washingtonRef = doc(db, "admin", document);

// Atomically increment the population of the city by 50.
await updateDoc(washingtonRef, {
    value: increment(-1)
});
if (document === "productsNumber") {
  setProductsC([])
  getProducts();
}else if (document === "productsHome2Number") {
  setProducts2HomeC([])
  getProducts2();
}else if (document === "carousel2Number") {
  setCarousel2C([])
  getCarousel2();
}else if (document === "products1Number") {
  setProducts1C([])
  getProductsPage1()
}else if (document === "products2Number") {
  setProducts2C([])
  getProductsPage2()
}else if (document === "products3Number") {
  setProducts3C([])
  getProductsPage3()
}else if (document === "products4Number") {
  setProducts4C([])
  getProductsPage4()
}
else if (document === "products5Number") {
  setProducts5C([])
  getProductsPage5()
}else if (document === "products6Number") {
  setProducts6C([])
  getProductsPage6()
}
setShowUpdate(false);
  }

  const choosePage=(index)=>{
    if (index === 0) {
      setPage("page1")
    }else if(index === 1){
      setPage("page2")
    }else{
      setPage("page3")
    }
  }
  const choosePage2=(index)=>{
    if (index === 0) {
      setPage("page4")
    }else if(index === 1){
      setPage("page5")
    }else{
      setPage("page6")
    }
  }

  return(
    <div className={admin.addProductsContainer}>
      <div style={{width:"90%"}}>
        <div style={{display:"flex",justifyContent:"flex-start",marginTop:"10px",marginBottom:"20px"}}><h2>Ajouter des produits:</h2></div>
        <div className={admin.choices}>

          {page === "home" && (<button onClick={()=> setView(!view)}>Produits (Home)</button>)}
          {page === "home" && (<button onClick={()=> setView9(!view9)}>Produits 2 (Home)</button>)}

          {page === "page1" && (<button onClick={()=> setView6(!view6)}>Produits (Product 1)</button>)}
          {page === "page2" && (<button onClick={()=> setView7(!view7)}>Produits (Product 2)</button>)}
          {page === "page3" && (<button onClick={()=> setView8(!view8)}>Produits (Product 3)</button>)}
          {page === "page4" && (<button onClick={()=> setView66(!view66)}>Produits (Product 4)</button>)}
          {page === "page5" && (<button onClick={()=> setView77(!view77)}>Produits (Product 5)</button>)}
          {page === "page6" && (<button onClick={()=> setView88(!view88)}>Produits (Product 6)</button>)}
        </div>
        <div className={admin.choices}>
        {page === "home" && (<button onClick={()=> setView2(!view2)}>Produits (Banner 6p)</button> )}
        {page === "home" && (<button onClick={()=> setView4(!view4)}>Carousel 1</button>)}
        {page === "home" && (<button onClick={()=> setView5(!view5)}>Carousel 2</button>)}
        {page === "home" && (<button onClick={()=> setView3(!view3)}>Packs</button>)}

        </div>
      <div className={admin.addProducts}>
        {view && (
          <div className={admin.modal}>
            <form onSubmit={file && addProduct}>
          <h3>Ajouter un produit (Home):</h3> 
          <input value={title} onChange={(e)=> setTitle(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input value={desc} onChange={(e)=> setDesc(e.target.value)} placeholder='Description du produit' type="text" required/>
          <input value={price} onChange={(e)=> setPrice(e.target.value)} placeholder='Prix du produit' type="number" required/>
          <span><input value={discount} style={{display:"inline",marginRight:"5px"}} onChange={(e)=> setDiscount(e.target.value)} placeholder='Discount (faculatative)' type="number" />%</span>
          {discount != 0 && (<input value={price2} type="number" />)}
          <input value={modele} onChange={(e)=> setModele(e.target.value)} placeholder='Modele' type="text" required/>
          <input value={poids} onChange={(e)=> setPoids(e.target.value)} placeholder='Poids' type="text" required/>
          <input value={couleur} onChange={(e)=> setColeur(e.target.value)} placeholder='Couleur' type="text" required/>
          <input value={materiel} onChange={(e)=> setMateriel(e.target.value)} placeholder='Materiel' type="text" required/>
          <textarea value={caracter} onChange={(e)=> setCaracter(e.target.value)} cols="49" rows="10" placeholder='Caracteristiques'></textarea>

          <input key={inputKey} onChange={(e)=> handleFile(e)} type="file" required/>
          <button type='submit'>Ajouter</button>
          <div>{file && (<img style={{width:"150px",height:"150px",marginLeft:"0px"}} src={URL?.createObjectURL(file)} alt="" />)}</div>
          </form>
          </div>
        )}
        {showUpdate && (
          <div style={{width:"450px",height:"500px",backgroundColor:"white",position:"fixed",zIndex:100,top:"80px",border:"1px solid gray",borderRadius:"5px",display:"flex",justifyContent:"center",overflowY:"scroll",paddingBottom:"10px"}}>
            <div className={admin.update} style={{width:"90%",marginTop:"20px"}}>
              <div onClick={()=> setShowUpdate(false)} style={{position:"absolute",top:0,right:"10px",cursor:"pointer"}}><h2>x</h2></div>
              <div
              style={{width:"100%"}}>
                <h2 style={{paddingBottom:"10px"}}>Modification:</h2>
              {inputs && (<input value={titleUpdate} onChange={(e)=> setTitleUpdate(e.target.value)} type="text" placeholder='Nom du produit' />)}
              {inputs2 && (<input value={descriptionUpdate} onChange={(e)=> setDiscriptionUpdate(e.target.value)}  type="text" placeholder='Description'/>)}
              {inputs3 && (<input value={price} onChange={(e)=> setPrice(e.target.value)} type="number" placeholder='Prix' />)}
              {inputs4 && (<input value={discount} onChange={(e)=> setDiscount(e.target.value)} type="number" placeholder='Discount'/>)}
              {inputs5 && (<input value={price2} onChange={(e)=> setPrice2(e.target.value)} type="number" placeholder='Ancienne prix' />)}
              {inputs7 && (<input value={modele} onChange={(e)=> setModele(e.target.value)} placeholder='Modele' type="text" required/>)}
              {inputs8 && (<input value={poids} onChange={(e)=> setPoids(e.target.value)} placeholder='Poids' type="text" required/>)}
              {inputs9 && (<input value={couleur} onChange={(e)=> setColeur(e.target.value)} placeholder='Couleur' type="text" required/>)}
              {inputs10 && (<input value={materiel} onChange={(e)=> setMateriel(e.target.value)} placeholder='Materiel' type="text" required/>)}
              {inputs11 && (<textarea value={caracter} onChange={(e)=> setCaracter(e.target.value)} cols="49" rows="10" placeholder='Caracteristiques'></textarea>)}
              {inputs6 && (<input key={inputKey} onChange={(e)=> setFileUpdate(e.target.files[0])} type="file" />)}
              {inputs66 && (<input  onChange={(e)=> setFileUpdate2(e.target.files[0])} type="file" />)}
              {inputs666 && (<input  onChange={(e)=> setFileUpdate3(e.target.files[0])} type="file" />)}


              <button style={{marginBottom:"10px"}} onClick={updateCollection !== "packs" ? updateProduct : updatePack}>Modifier</button>
              <button onClick={()=> deleteProduct()}>Supprimer</button>
              </div>
            </div>
          </div>
        )}

{view9 && (
          <div className={admin.modal}>
            <form onSubmit={file && addProductHome2}>
          <h3>Ajouter un produit 2 (Home):</h3> 
          <input value={title} onChange={(e)=> setTitle(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input value={desc} onChange={(e)=> setDesc(e.target.value)} placeholder='Description du produit' type="text" required/>
          <input value={price} onChange={(e)=> setPrice(e.target.value)} placeholder='Prix du produit' type="number" required/>
          <span><input value={discount} style={{display:"inline",marginRight:"5px"}} onChange={(e)=> setDiscount(e.target.value)} placeholder='Discount (faculatative)' type="number" />%</span>
          {discount != 0 && (<input value={price2} type="number" />)}
          <input value={modele} onChange={(e)=> setModele(e.target.value)} placeholder='Modele' type="text" required/>
          <input value={poids} onChange={(e)=> setPoids(e.target.value)} placeholder='Poids' type="text" required/>
          <input value={couleur} onChange={(e)=> setColeur(e.target.value)} placeholder='Couleur' type="text" required/>
          <input value={materiel} onChange={(e)=> setMateriel(e.target.value)} placeholder='Materiel' type="text" required/>
          <textarea value={caracter} onChange={(e)=> setCaracter(e.target.value)} cols="49" rows="10" placeholder='Caracteristiques'></textarea>
          <input key={inputKey} onChange={(e)=> handleFile(e)} type="file" required/>
          <button type='submit'>Ajouter</button>

          </form>
          </div>
        )}


{view6 && (
          <div className={admin.modal}>
            <form onSubmit={prod1File && addProduct1}>
          <h3>Ajouter un produit (Products 1):</h3> 
          <input value={title} onChange={(e)=> setTitle(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input value={desc} onChange={(e)=> setDesc(e.target.value)} placeholder='Description du produit' type="text" required/>
          <input value={price} onChange={(e)=> setPrice(e.target.value)} placeholder='Prix du produit' type="number" required/>
          <span><input value={discount} style={{display:"inline",marginRight:"5px"}} onChange={(e)=> setDiscount(e.target.value)} placeholder='Discount (faculatative)' type="number" />%</span>
          {discount != 0 && (<input value={price2} type="number" />)}
          <input value={modele} onChange={(e)=> setModele(e.target.value)} placeholder='Modele' type="text" required/>
          <input value={poids} onChange={(e)=> setPoids(e.target.value)} placeholder='Poids' type="text" required/>
          <input value={couleur} onChange={(e)=> setColeur(e.target.value)} placeholder='Couleur' type="text" required/>
          <input value={materiel} onChange={(e)=> setMateriel(e.target.value)} placeholder='Materiel' type="text" required/>
          <textarea value={caracter} onChange={(e)=> setCaracter(e.target.value)} cols="49" rows="10" placeholder='Caracteristiques'></textarea>
          <input key={inputKey} onChange={(e)=> handleProdFile(e)} type="file" required/>
          <button type='submit'>Ajouter</button>
          <div>{prod1File && (<img style={{width:"150px",height:"150px",marginLeft:"0px"}} src={URL?.createObjectURL(prod1File)} alt="" />)}</div>
          </form>
          </div>
        )}
        {view66 && (
          <div className={admin.modal}>
            <form onSubmit={prod4File && addProduct4}>
          <h3>Ajouter un produit (Products 4):</h3> 
          <input value={title} onChange={(e)=> setTitle(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input value={desc} onChange={(e)=> setDesc(e.target.value)} placeholder='Description du produit' type="text" required/>
          <input value={price} onChange={(e)=> setPrice(e.target.value)} placeholder='Prix du produit' type="number" required/>
          <span><input value={discount} style={{display:"inline",marginRight:"5px"}} onChange={(e)=> setDiscount(e.target.value)} placeholder='Discount (faculatative)' type="number" />%</span>
          {discount != 0 && (<input value={price2} type="number" />)}
          <input value={modele} onChange={(e)=> setModele(e.target.value)} placeholder='Modele' type="text" required/>
          <input value={poids} onChange={(e)=> setPoids(e.target.value)} placeholder='Poids' type="text" required/>
          <input value={couleur} onChange={(e)=> setColeur(e.target.value)} placeholder='Couleur' type="text" required/>
          <input value={materiel} onChange={(e)=> setMateriel(e.target.value)} placeholder='Materiel' type="text" required/>
          <textarea value={caracter} onChange={(e)=> setCaracter(e.target.value)} cols="49" rows="10" placeholder='Caracteristiques'></textarea>
          <input key={inputKey} onChange={(e)=> handleProd4File(e)} type="file" required/>
          <button type='submit'>Ajouter</button>
          <div>{prod1File && (<img style={{width:"150px",height:"150px",marginLeft:"0px"}} src={URL?.createObjectURL(prod4File)} alt="" />)}</div>
          </form>
          </div>
        )}
        {view77 && (
          <div className={admin.modal}>
            <form onSubmit={prod5File && addProduct5}>
          <h3>Ajouter un produit (Products 5):</h3> 
          <input value={title} onChange={(e)=> setTitle(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input value={desc} onChange={(e)=> setDesc(e.target.value)} placeholder='Description du produit' type="text" required/>
          <input value={price} onChange={(e)=> setPrice(e.target.value)} placeholder='Prix du produit' type="number" required/>
          <span><input value={discount} style={{display:"inline",marginRight:"5px"}} onChange={(e)=> setDiscount(e.target.value)} placeholder='Discount (faculatative)' type="number" />%</span>
          {discount != 0 && (<input value={price2} type="number" />)}
          <input value={modele} onChange={(e)=> setModele(e.target.value)} placeholder='Modele' type="text" required/>
          <input value={poids} onChange={(e)=> setPoids(e.target.value)} placeholder='Poids' type="text" required/>
          <input value={couleur} onChange={(e)=> setColeur(e.target.value)} placeholder='Couleur' type="text" required/>
          <input value={materiel} onChange={(e)=> setMateriel(e.target.value)} placeholder='Materiel' type="text" required/>
          <textarea value={caracter} onChange={(e)=> setCaracter(e.target.value)} cols="49" rows="10" placeholder='Caracteristiques'></textarea>
          <input key={inputKey} onChange={(e)=> handleProd5File(e)} type="file" required/>
          <button type='submit'>Ajouter</button>
          <div>{prod1File && (<img style={{width:"150px",height:"150px",marginLeft:"0px"}} src={URL?.createObjectURL(prod5File)} alt="" />)}</div>
          </form>
          </div>
        )}
        {view88 && (
          <div className={admin.modal}>
            <form onSubmit={prod6File && addProduct6}>
          <h3>Ajouter un produit (Products 6):</h3> 
          <input value={title} onChange={(e)=> setTitle(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input value={desc} onChange={(e)=> setDesc(e.target.value)} placeholder='Description du produit' type="text" required/>
          <input value={price} onChange={(e)=> setPrice(e.target.value)} placeholder='Prix du produit' type="number" required/>
          <span><input value={discount} style={{display:"inline",marginRight:"5px"}} onChange={(e)=> setDiscount(e.target.value)} placeholder='Discount (faculatative)' type="number" />%</span>
          {discount != 0 && (<input value={price2} type="number" />)}
          <input value={modele} onChange={(e)=> setModele(e.target.value)} placeholder='Modele' type="text" required/>
          <input value={poids} onChange={(e)=> setPoids(e.target.value)} placeholder='Poids' type="text" required/>
          <input value={couleur} onChange={(e)=> setColeur(e.target.value)} placeholder='Couleur' type="text" required/>
          <input value={materiel} onChange={(e)=> setMateriel(e.target.value)} placeholder='Materiel' type="text" required/>
          <textarea value={caracter} onChange={(e)=> setCaracter(e.target.value)} cols="49" rows="10" placeholder='Caracteristiques'></textarea>
          <input key={inputKey} onChange={(e)=> handleProd6File(e)} type="file" required/>
          <button type='submit'>Ajouter</button>
          <div>{prod1File && (<img style={{width:"150px",height:"150px",marginLeft:"0px"}} src={URL?.createObjectURL(prod6File)} alt="" />)}</div>
          </form>
          </div>
        )}

{view7 && (
          <div className={admin.modal}>
            <form onSubmit={prod2File && addProduct2}>
          <h3>Ajouter un produit (Products 2):</h3> 
          <input value={title} onChange={(e)=> setTitle(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input value={desc} onChange={(e)=> setDesc(e.target.value)} placeholder='Description du produit' type="text" required/>
          <input value={price} onChange={(e)=> setPrice(e.target.value)} placeholder='Prix du produit' type="number" required/>
          <span><input value={discount} style={{display:"inline",marginRight:"5px"}} onChange={(e)=> setDiscount(e.target.value)} placeholder='Discount (faculatative)' type="number" />%</span>
          {discount != 0 && (<input value={price2} type="number" />)}
          <input value={modele} onChange={(e)=> setModele(e.target.value)} placeholder='Modele' type="text" required/>
          <input value={poids} onChange={(e)=> setPoids(e.target.value)} placeholder='Poids' type="text" required/>
          <input value={couleur} onChange={(e)=> setColeur(e.target.value)} placeholder='Couleur' type="text" required/>
          <input value={materiel} onChange={(e)=> setMateriel(e.target.value)} placeholder='Materiel' type="text" required/>
          <textarea value={caracter} onChange={(e)=> setCaracter(e.target.value)} cols="49" rows="10" placeholder='Caracteristiques'></textarea>
          <input key={inputKey} onChange={(e)=> handleProd2File(e)} type="file" required/>
          <button type='submit'>Ajouter</button>
          <div>{prod2File && (<img style={{width:"150px",height:"150px",marginLeft:"0px"}} src={URL?.createObjectURL(prod2File)} alt="" />)}</div>
          </form>
          </div>
        )}

{view8 && (
          <div className={admin.modal}>
            <form onSubmit={prod2File && addProduct3}>
          <h3>Ajouter un produit (Products 3):</h3> 
          <input value={title} onChange={(e)=> setTitle(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input value={desc} onChange={(e)=> setDesc(e.target.value)} placeholder='Description du produit' type="text" required/>
          <input value={price} onChange={(e)=> setPrice(e.target.value)} placeholder='Prix du produit' type="number" required/>
          <span><input value={discount} style={{display:"inline",marginRight:"5px"}} onChange={(e)=> setDiscount(e.target.value)} placeholder='Discount (faculatative)' type="number" />%</span>
          {discount != 0 && (<input value={price2} type="number" />)}
          <input value={modele} onChange={(e)=> setModele(e.target.value)} placeholder='Modele' type="text" required/>
          <input value={poids} onChange={(e)=> setPoids(e.target.value)} placeholder='Poids' type="text" required/>
          <input value={couleur} onChange={(e)=> setColeur(e.target.value)} placeholder='Couleur' type="text" required/>
          <input value={materiel} onChange={(e)=> setMateriel(e.target.value)} placeholder='Materiel' type="text" required/>
          <textarea value={caracter} onChange={(e)=> setCaracter(e.target.value)} cols="49" rows="10" placeholder='Caracteristiques'></textarea>
          <input key={inputKey} onChange={(e)=> handleProd3File(e)} type="file" required/>
          <button type='submit'>Ajouter</button>
          <div>{prod3File && (<img style={{width:"150px",height:"150px",marginLeft:"0px"}} src={URL?.createObjectURL(prod3File)} alt="" />)}</div>
          </form>
          </div>
        )}
        

        {view2 && (
          <div className={admin.modal}>
          <form onSubmit={addProductBanner}>
          <h3>Ajouter un produit (Banner 1):</h3> 
          <p style={{marginBottom:"4px"}}>Product image 1</p>
          <input value={bTitle} onChange={(e)=> setBtitle(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input onChange={(e)=> handleFileb(e)} type="file" required/>
          <button type='submit'>Ajouter</button>
          <div>{bfile && (<img style={{width:"150px",height:"150px",marginLeft:"0px"}} src={URL?.createObjectURL(bfile)} alt="" />)}</div>
          </form>
  
          <form onSubmit={addProductBanner2}>
          <p style={{marginBottom:"4px"}}>Product image 2</p>
          <input value={bTitle2} onChange={(e)=> setBtitle2(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input onChange={(e)=> handleFileb2(e)} type="file" required/>
          <button type='submit'>Ajouter</button>
          <div>{bfile2 && (<img style={{width:"150px",height:"150px",marginLeft:"0px"}} src={URL?.createObjectURL(bfile2)} alt="" />)}</div>
  
  
          </form>
          <form onSubmit={addProductBanner3}>
          <p style={{marginBottom:"4px"}}>Product image 3</p>
          <input value={bTitle3} onChange={(e)=> setBtitle3(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input onChange={(e)=> handleFileb3(e)} type="file" required/>
          <button type='submit'>Ajouter</button>
          <div>{bfile3 && (<img style={{width:"150px",height:"150px",marginLeft:"0px"}} src={URL?.createObjectURL(bfile3)} alt="" />)}</div>
  
          </form>
          </div>
        )}


{view2 && (
          <div className={admin.modal}>
          <form onSubmit={addProductBanner4}>
          <h3>Ajouter un produit (Banner 2):</h3> 
          <p style={{marginBottom:"4px"}}>Product image 1</p>
          <input value={bTitle4} onChange={(e)=> setBtitle4(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input onChange={(e)=> handleFileb4(e)} type="file" required/>
          <button type='submit'>Ajouter</button>
          <div>{bfile4 && (<img style={{width:"150px",height:"150px",marginLeft:"0px"}} src={URL?.createObjectURL(bfile4)} alt="" />)}</div>
          </form>
  
          <form onSubmit={addProductBanner5}>
          <p style={{marginBottom:"4px"}}>Product image 2</p>
          <input value={bTitle5} onChange={(e)=> setBtitle5(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input onChange={(e)=> handleFileb5(e)} type="file" required/>
          <button type='submit'>Ajouter</button>
          <div>{bfile5 && (<img style={{width:"150px",height:"150px",marginLeft:"0px"}} src={URL?.createObjectURL(bfile5)} alt="" />)}</div>
  
  
          </form>
          <form onSubmit={addProductBanner6}>
          <p style={{marginBottom:"4px"}}>Product image 3</p>
          <input value={bTitle6} onChange={(e)=> setBtitle6(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input onChange={(e)=> handleFileb6(e)} type="file" required/>
          <button type='submit'>Ajouter</button>
          <div>{bfile6 && (<img style={{width:"150px",height:"150px",marginLeft:"0px"}} src={URL?.createObjectURL(bfile6)} alt="" />)}</div>
  
          </form>
          </div>
        )}


        {view3 && (
          <div className={admin.modal}>
          <form onSubmit={addPack}>
                  <h3>Ajouter un pack (Home):</h3> 
                  <input value={pTitle} onChange={(e)=> setPtitle(e.target.value)} placeholder='Titre de pack' type="text" required/>
                  <input value={pricePack} onChange={(e)=> setPricePack(e.target.value)} placeholder='Prix du produit' type="number" required/>
                  <span><input value={discountPack} style={{display:"inline",marginRight:"5px"}} onChange={(e)=> setDiscountPack(e.target.value)} placeholder='Discount (faculatative)' type="number" />%</span>
                  {discountPack != 0 && (<input value={oldPricePack} type="number" />)}
                  <input onChange={(e)=> handleFilePack(e)} type="file" required/>
                  <input onChange={(e)=> handleFile2Pack(e)} type="file" required/>
                  <input onChange={(e)=> handleFile3Pack(e)} type="file" required/>
          
                  <button type='submit'>Ajouter</button>
                  <div>{pfile && (<img style={{width:"100px",height:"100px",marginLeft:"0px"}} src={URL?.createObjectURL(pfile)} alt="" />)} {pfile2 && (<img style={{width:"100px",height:"100px",marginLeft:"0px"}} src={URL?.createObjectURL(pfile2)} alt="" />)} {pfile3 && (<img style={{width:"100px",height:"100px",marginLeft:"0px"}} src={URL?.createObjectURL(pfile3)} alt="" />)}</div>
          </form>
          
          <form onSubmit={addPack2}>
                  <h3>Ajouter un pack 2 (Home):</h3> 
                  <input value={p2Title} onChange={(e)=> setP2title(e.target.value)} placeholder='Titre de pack' type="text" required/>
                  <input value={pricePack2} onChange={(e)=> setPricePack2(e.target.value)} placeholder='Prix du produit' type="number" required/>
                  <span><input value={discountPack2} style={{display:"inline",marginRight:"5px"}} onChange={(e)=> setDiscountPack2(e.target.value)} placeholder='Discount (faculatative)' type="number" />%</span>
                  {discountPack2 != 0 && (<input value={oldPricePack2} type="number" />)}
                  <input onChange={(e)=> handleFilePack2(e)} type="file" required/>
                  <input onChange={(e)=> handleFile2Pack2(e)} type="file" required/>
                  <input onChange={(e)=> handleFile3Pack2(e)} type="file" required/>
          
                  <button type='submit'>Ajouter</button>
                  <div>{p2file && (<img style={{width:"100px",height:"100px",marginLeft:"0px"}} src={URL?.createObjectURL(p2file)} alt="" />)} {p2file2 && (<img style={{width:"100px",height:"100px",marginLeft:"0px"}} src={URL?.createObjectURL(p2file2)} alt="" />)} {p2file3 && (<img style={{width:"100px",height:"100px",marginLeft:"0px"}} src={URL?.createObjectURL(p2file3)} alt="" />)}</div>
          </form>
          </div>
        )}

        {view4 && (
          <div className={admin.modal}>
          <form onSubmit={addImages}>
                  <h3>Ajouter des image (Home slider 1):</h3> 
                  <h4>Image 1</h4> <input key={inputKey} onChange={(e)=> handleFileImage(e)} type="file" />
                  <button type='submit'>Ajouter</button>
          </form>
          </div>
        )}

        {view5 && (
          <div className={admin.modal}>
          <form onSubmit={addImages22}>
                  <h3>Ajouter des image (Home slider 2):</h3><br />
                  <h4>Image 1</h4>
              <input value={title} onChange={(e)=> setTitle(e.target.value)} placeholder='Nom du produit' type="text" required/>
          <input value={desc} onChange={(e)=> setDesc(e.target.value)} placeholder='Description du produit' type="text" required/>
          <input value={price} onChange={(e)=> setPrice(e.target.value)} placeholder='Prix du produit' type="number" required/>
          <span><input value={discount} style={{display:"inline",marginRight:"5px"}} onChange={(e)=> setDiscount(e.target.value)} placeholder='Discount (faculatative)' type="number" />%</span>
          {discount != 0 && (<input value={price2} type="number" />)}
          <input value={modele} onChange={(e)=> setModele(e.target.value)} placeholder='Modele' type="text" required/>
          <input value={poids} onChange={(e)=> setPoids(e.target.value)} placeholder='Poids' type="text" required/>
          <input value={couleur} onChange={(e)=> setColeur(e.target.value)} placeholder='Couleur' type="text" required/>
          <input value={materiel} onChange={(e)=> setMateriel(e.target.value)} placeholder='Materiel' type="text" required/>
          <textarea value={caracter} onChange={(e)=> setCaracter(e.target.value)} cols="49" rows="10" placeholder='Caracteristiques'></textarea>
          <input key={inputKey} onChange={(e)=> handleFile(e)} type="file" required/>
                  <button type='submit'>Ajouter</button>
          </form>
          </div>
        )}

    </div>

    <div style={{display:"flex",justifyContent:"flex-start",marginTop:"10px",marginBottom:"20px"}}><h2>Modification des produits:</h2></div>














{page === "home" && (
  <div style={{width:"100%"}}>
    <div className={admin.home}>

<div className={home.homeBanner}>

</div>

<center>
<div className={home.firstBanner}>
  {bannerC?.map((items,index) => {return(
    <div style={{backgroundImage: `url(${items.imgUrl})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPosition:"center",position:"relative",cursor:"pointer"}} className={home.image}>
           <div onClick={()=> {setUpdateId(items.id);setUpdateCollection("banners"); setUpdateDocumentIndex(index);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}> <img style={{width:"15px",height:"15px"}} src={edit} alt="" /> </div>
    <div  onClick={()=> choosePage(index)} className={home.shadow}>
    <p>{items.title}</p>
    </div>
    </div>
  )})}
</div>

<div className={home.firstBanner}>
  {banner2C?.map((items,index) => {return(
    <div style={{backgroundImage: `url(${items.imgUrl})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPosition:"center",position:"relative",cursor:"pointer"}} className={home.image}>
           <div onClick={()=> {setUpdateId(items.id);setUpdateCollection("banners2"); setUpdateDocumentIndex(index);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}> <img style={{width:"15px",height:"15px"}} src={edit} alt="" /> </div>
    <div onClick={()=> choosePage2(index)} className={home.shadow}>
    <p>{items.title}</p>
    </div>
    </div>
  )})}
</div>
</center>


<div className={home.secondBanner}>
<CarouselProvider
  naturalSlideWidth={100} 
  naturalSlideHeight={20}
  visibleSlides={8}
  totalSlides={8}
  infinite={true} 

>
<center style={{position:"relative"}}>
<Slider className={home.slide}>
    <Slide  index={0}>
      <div className={home.slideBox}>
        <img src={carouselC[0]?.imgUrl} alt="" />
        <div onClick={()=> {setUpdateId(carouselC[0].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(0);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      </div>
    </Slide>
    <Slide  index={1}>
    <div className={home.slideBox}>
    <img src={carouselC[1]?.imgUrl} alt="" />
    <div onClick={()=> {setUpdateId(carouselC[1].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(1);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
    </div>
    </Slide>
    <Slide  index={2}>
      <div className={home.slideBox}>
      <img src={carouselC[2]?.imgUrl} alt="" />
      <div onClick={()=> {setUpdateId(carouselC[2].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(2);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

    </div>
    </Slide>
    <Slide  index={3}>
      <div className={home.slideBox}>
      <img src={carouselC[3]?.imgUrl} alt="" />
      <div onClick={()=> {setUpdateId(carouselC[3].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(3);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

    </div>
    </Slide>
    <Slide index={4}>
      <div className={home.slideBox}>
      <img src={carouselC[4]?.imgUrl} alt="" />
      <div onClick={()=> {setUpdateId(carouselC[4].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(4);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

    </div>
    </Slide>
    <Slide index={5}>
      <div className={home.slideBox}>
      <img src={carouselC[5]?.imgUrl} alt="" />
      <div onClick={()=> {setUpdateId(carouselC[5].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(5);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

    </div>
    </Slide>
    <Slide index={6}>
      <div className={home.slideBox}>
      <img src={carouselC[6]?.imgUrl} alt="" />
      <div onClick={()=> {setUpdateId(carouselC[6].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(6);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

    </div>
    </Slide>
    <Slide index={7}>
      <div className={home.slideBox}>
      <img src={carouselC[7]?.imgUrl} alt="" />
      <div onClick={()=> {setUpdateId(carouselC[7].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(7);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

    </div>
    </Slide> 
  </Slider>
  
</center>
</CarouselProvider>
</div>


<div className={home.secondBanner2}>
<CarouselProvider
  naturalSlideWidth={100}
  naturalSlideHeight={20}
  visibleSlides={4}
  totalSlides={8}
  infinite={true} 

>
<center style={{position:"relative"}}>
<Slider className={home.slide}>
<Slide  index={0}>
      <div className={home.slideBox}>
        <img src={carouselC[0]?.imgUrl} alt="" />
        <div onClick={()=> {setUpdateId(carouselC[0].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(0);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      </div>
    </Slide>
    <Slide  index={1}>
    <div className={home.slideBox}>
    <img src={carouselC[1]?.imgUrl} alt="" />
    <div onClick={()=> {setUpdateId(carouselC[1].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(1);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
    </div>
    </Slide>
    <Slide  index={2}>
      <div className={home.slideBox}>
      <img src={carouselC[2]?.imgUrl} alt="" />
      <div onClick={()=> {setUpdateId(carouselC[2].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(2);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

    </div>
    </Slide>
    <Slide  index={3}>
      <div className={home.slideBox}>
      <img src={carouselC[3]?.imgUrl} alt="" />
      <div onClick={()=> {setUpdateId(carouselC[3].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(3);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

    </div>
    </Slide>
    <Slide index={4}>
      <div className={home.slideBox}>
      <img src={carouselC[4]?.imgUrl} alt="" />
      <div onClick={()=> {setUpdateId(carouselC[4].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(4);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

    </div>
    </Slide>
    <Slide index={5}>
      <div className={home.slideBox}>
      <img src={carouselC[5]?.imgUrl} alt="" />
      <div onClick={()=> {setUpdateId(carouselC[5].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(5);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

    </div>
    </Slide>
    <Slide index={6}>
      <div className={home.slideBox}>
      <img src={carouselC[6]?.imgUrl} alt="" />
      <div onClick={()=> {setUpdateId(carouselC[6].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(6);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

    </div>
    </Slide>
    <Slide index={7}>
      <div className={home.slideBox}>
      <img src={carouselC[7]?.imgUrl} alt="" />
      <div onClick={()=> {setUpdateId(carouselC[7].id);setUpdateCollection("carousel"); setUpdateDocumentIndex(7);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"50%",left:"35%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

    </div>
    </Slide> 
  </Slider>
  <ButtonBack className={home.but1}>{"<"}</ButtonBack>
  <ButtonNext className={home.but2}>{">"}</ButtonNext>
</center>
</CarouselProvider>
</div>



<center style={{marginTop:"20px"}}>
  <h1 className={home.promoTitle}>تخفيض %40</h1>
</center>



<div className={home.products}>
  {productsC?.map((item,index)=>{
    return(
      <div className={home.product}>
        <Link style={{textDecoration:"none"}}>
        <img src={item?.imgUrl} alt="" />
        <div onClick={()=> {setUpdateId(item?.id);setUpdateCollection("products"); setUpdateDocumentIndex(index);setShowUpdate(true)}}  className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
        <div className={home.discount}><p>{item?.discount}%</p></div>
        <div className={home.infos}>
        <h4 className={home.name}>{item?.title}</h4>
        <p className={home.desc}>{item?.desc}</p>
        <div className={home.priceSection}>
        <p className={home.oldPrice}>{item?.price} DH</p>
        <p className={home.price}>{item?.price2} DH</p> 
        </div>
        </div>
        </Link>
      </div>
    );
  })}
</div>

<div className={home.bottomPackProducts}>
  <div style={{position:"relative"}} className={home.packProduct}>
    <center><p className={home.packTitle}> {pack1C?.title} </p></center>
    <div className={home.edit}  onClick={()=> {setUpdateId(pack1C.id);setUpdateCollection("packs"); setUpdateDocumentIndex(0);setShowUpdate(true)}} style={{position:"absolute",top:"2%",right:"2%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
    <div className={home.packImages}>
    <div className={home.adminPack}>
      <img src={pack1C?.imgUrl} alt=""/>
      </div>
      <div className={home.adminPack}>
      <img src={pack1C?.imgUrl2} alt="" />
      </div>
      <div className={home.adminPack}>
      <img src={pack1C?.imgUrl3} alt="" />
      </div>
    </div>
   <center>
   <div className={home.packPrice}>
    <div className={home.pricing}> 
    <h1 className={home.oldPrice}>{pack1C?.price2}DH</h1>
    <h1 className={home.price}>{pack1C?.price}DH</h1>
    </div>
    <button>Buy now</button>
  </div>
   </center>
  </div>

</div>

<div className={home.thirdBanner}>
<CarouselProvider
  naturalSlideWidth={100}
  naturalSlideHeight={20}
  visibleSlides={5}
  totalSlides={10}
  infinite={true} 

>
<center style={{position:"relative"}}>
<Slider className={home.slide}>
    <Slide  index={0}>
      <div className={home.slideBox}>
        <img src={carousel2C[0]?.imgUrl} alt="" />
        <div onClick={()=> {setUpdateId(carousel2C[0]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(0);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

        <p className={home.oldPrice}>{carousel2C[0]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[0]?.price2}DH</p>
      </div>
    </Slide>
    <Slide  index={1}>
    <div className={home.slideBox}>
    <img src={carousel2C[1]?.imgUrl} alt="" />
            <div onClick={()=> {setUpdateId(carousel2C[1]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(1);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
    <p className={home.oldPrice}>{carousel2C[1]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[1]?.price2}DH</p>
    </div>
    </Slide>
    <Slide  index={2}>
      <div className={home.slideBox}>
      <img src={carousel2C[2]?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(carousel2C[2]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(2);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[2]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[2]?.price2}DH</p>
    </div>
    </Slide>
    <Slide  index={3}>
      <div className={home.slideBox}>
      <img src={carousel2C[3]?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(carousel2C[3]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(3);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[3]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[3]?.price2}DH</p>
    </div>
    </Slide>
    <Slide index={4}>
      <div className={home.slideBox}>
      <img src={carousel2C[4]?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(carousel2C[4]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(4);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[4]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[4]?.price2}DH</p>
    </div>
    </Slide>
    <Slide index={5}>
      <div className={home.slideBox}>
      <img src={carousel2C[5]?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(carousel2C[5]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(5);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[5]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[5]?.price2}DH</p>
    </div>
    </Slide>
    <Slide index={6}>
      <div className={home.slideBox}>
      <img src={carousel2C[6]?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(carousel2C[6]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(6);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[6]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[6]?.price2}DH</p>
    </div>
    </Slide>
    <Slide index={7}>
      <div className={home.slideBox}>
      <img src={carousel2C[7]?.imgUrl} alt="" />
      <div onClick={()=> {setUpdateId(carousel2C[7]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(7);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[7]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[7]?.price2}DH</p>
    </div>
    </Slide>
    <Slide index={8}>
      <div className={home.slideBox}>
      <img src={carousel2C[8]?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(carousel2C[8]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(8);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[8]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[8]?.price2}DH</p>
    </div>
    </Slide>
    <Slide index={9}>
      <div className={home.slideBox}>
      <img src={carousel2C[9]?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(carousel2C[9]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(9);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[9]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[9]?.price2}DH</p>
    </div>
    </Slide>
  </Slider>
  <ButtonBack className={home.but1}>{"<"}</ButtonBack>
  <ButtonNext className={home.but2}>{">"}</ButtonNext>
</center>
</CarouselProvider>
</div>

<div className={home.thirdBanner3}>
<CarouselProvider
  naturalSlideWidth={100}
  naturalSlideHeight={20}
  visibleSlides={3}
  totalSlides={10}
  infinite={true} 

>
<center style={{position:"relative"}}>
<Slider className={home.slide}>
<Slide  index={0}>
      <div className={home.slideBox}>
        <img src={carousel2C[0]?.imgUrl} alt="" />
        <div onClick={()=> {setUpdateId(carousel2C[0]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(0);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

        <p className={home.oldPrice}>{carousel2C[0]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[0]?.price2}DH</p>
      </div>
    </Slide>
    <Slide  index={1}>
    <div className={home.slideBox}>
    <img src={carousel2C[1]?.imgUrl} alt="" />
            <div onClick={()=> {setUpdateId(carousel2C[1]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(1);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
    <p className={home.oldPrice}>{carousel2C[1]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[1]?.price2}DH</p>
    </div>
    </Slide>
    <Slide  index={2}>
      <div className={home.slideBox}>
      <img src={carousel2C[2]?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(carousel2C[2]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(2);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[2]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[2]?.price2}DH</p>
    </div>
    </Slide>
    <Slide  index={3}>
      <div className={home.slideBox}>
      <img src={carousel2C[3]?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(carousel2C[3]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(3);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[3]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[3]?.price2}DH</p>
    </div>
    </Slide>
    <Slide index={4}>
      <div className={home.slideBox}>
      <img src={carousel2C[4]?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(carousel2C[4]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(4);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[4]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[4]?.price2}DH</p>
    </div>
    </Slide>
    <Slide index={5}>
      <div className={home.slideBox}>
      <img src={carousel2C[5]?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(carousel2C[5]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(5);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[5]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[5]?.price2}DH</p>
    </div>
    </Slide>
    <Slide index={6}>
      <div className={home.slideBox}>
      <img src={carousel2C[6]?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(carousel2C[6]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(6);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[6]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[6]?.price2}DH</p>
    </div>
    </Slide>
    <Slide index={7}>
      <div className={home.slideBox}>
      <img src={carousel2C[7]?.imgUrl} alt="" />
      <div onClick={()=> {setUpdateId(carousel2C[7]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(7);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[7]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[7]?.price2}DH</p>
    </div>
    </Slide>
    <Slide index={8}>
      <div className={home.slideBox}>
      <img src={carousel2C[8]?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(carousel2C[8]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(8);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[8]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[8]?.price2}DH</p>
    </div>
    </Slide>
    <Slide index={9}>
      <div className={home.slideBox}>
      <img src={carousel2C[9]?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(carousel2C[9]?.id);setUpdateCollection("carousel2"); setUpdateDocumentIndex(9);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
      <p className={home.oldPrice}>{carousel2C[9]?.price}DH</p>
        <p className={home.newPrice}>{carousel2C[9]?.price2}DH</p>
    </div>
    </Slide>
  </Slider>
  <ButtonBack className={home.but1}>{"<"}</ButtonBack>
  <ButtonNext className={home.but2}>{">"}</ButtonNext>
</center>
</CarouselProvider>
</div>

<div className={home.products}>
  {products2HomeC?.map((item,index)=>{
    return(
      <div className={home.product}>
        <Link style={{textDecoration:"none"}}>
        <img src={item?.imgUrl} alt="" />
        <div onClick={()=> {setUpdateId(item?.id);setUpdateCollection("products2Home"); setUpdateDocumentIndex(index);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
        <div className={home.discount}><p>{item?.discount}%</p></div>
        <div className={home.infos}>
        <h4 className={home.name}>{item?.title}</h4>
        <p className={home.desc}>{item?.desc}</p>
        <div className={home.priceSection}>
        <p className={home.oldPrice}>{item?.price} DH</p>
        <p className={home.price}>{item?.price2} DH</p>
        </div>
        </div>
        </Link>
        
      </div>
    );
  })}
</div>








<div className={home.bottomPackProducts}>
  <div style={{position:"relative"}} className={home.packProduct}>
    <center><p className={home.packTitle}>{pack2C?.title}</p></center>
    <div onClick={()=> {setUpdateId(pack2C.id);setUpdateCollection("packs"); setUpdateDocumentIndex(0);setShowUpdate(true)}} className={home.edit} style={{position:"absolute",top:"2%",right:"2%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>

    <div className={home.packImages}>
      <div className={home.adminPack}>
      <img src={pack2C?.imgUrl} alt="" />
      </div>
      <div className={home.adminPack}>
      <img src={pack2C?.imgUrl2} alt="" />
      </div>
      <div className={home.adminPack}>
      <img src={pack2C?.imgUrl3} alt="" />
      </div>
    </div>
   <center>
   <div className={home.packPrice}>
    <div className={home.pricing}>
    <h1 className={home.oldPrice}>{pack2C?.price}DH</h1>
    <h1 className={home.price}>{pack2C?.price2}DH</h1>
    </div>
    <button>Buy now</button>
  </div>
   </center>
  </div>

</div>

</div>
  </div>
)}
{page === "page1" && (
  <div style={{textAlign:"left"}}>
    <p style={{cursor:"pointer",backgroundColor:"red",color:"white",width:"fit-content",paddingLeft:"8px",paddingRight:"8px",borderRadius:"2px",height:"25px"}} onClick={()=> setPage("home")}>الرجوع للصفحة الرئيسية</p>
    <div className={home.products}>
        {products1C?.map((item,index)=>{
          return(
            <div className={home.product}>
              <Link style={{textDecoration:"none"}}>
              <img src={item?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(item?.id);setUpdateCollection("products1"); setUpdateDocumentIndex(index);setShowUpdate(true)}}  className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
              <div className={home.discount}><p>{item?.discount}%</p></div>
              <div className={home.infos}>
              <h4 className={home.name}>{item?.title}</h4>
              <p className={home.desc}>{item?.desc}</p>
              <div className={home.priceSection}>
              <p className={home.oldPrice}>{item?.price} DH</p>
              <p className={home.price}>{item?.price2} DH</p>
              </div>
              </div>
              </Link>
                 </div>
          );
        })}
      </div>
  </div>
)}
{page === "page2" && (
  <div style={{textAlign:"left"}}>
        <p style={{cursor:"pointer",backgroundColor:"red",color:"white",width:"fit-content",paddingLeft:"8px",paddingRight:"8px",borderRadius:"2px",height:"25px"}} onClick={()=> setPage("home")}>الرجوع للصفحة الرئيسية</p>
       <div className={home.products}>
        {products2C?.map((item,index)=>{
          return(
            <div className={home.product}>
              <Link style={{textDecoration:"none"}}>
              <img src={item?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(item?.id);setUpdateCollection("products2"); setUpdateDocumentIndex(index);setShowUpdate(true)}}  className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
              <div className={home.discount}><p>{item?.discount}%</p></div>
              <div className={home.infos}>
              <h4 className={home.name}>{item?.title}</h4>
              <p className={home.desc}>{item?.desc}</p>
              <div className={home.priceSection}>
              <p className={home.oldPrice}>{item?.price} DH</p>
              <p className={home.price}>{item?.price2} DH</p>
              </div>
              </div>
              </Link>
               </div>
          );
        })}
      </div>
  </div>
)}
{page === "page3" && (
  <div style={{textAlign:"left"}}>
        <p style={{cursor:"pointer",backgroundColor:"red",color:"white",width:"fit-content",paddingLeft:"8px",paddingRight:"8px",borderRadius:"2px",height:"25px"}} onClick={()=> setPage("home")}>الرجوع للصفحة الرئيسية</p>
       <div className={home.products}>
        {products3C?.map((item,index)=>{
          return(
            <div className={home.product}>
              <Link style={{textDecoration:"none"}}>
              <img src={item?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(item?.id);setUpdateCollection("products3"); setUpdateDocumentIndex(index);setShowUpdate(true)}}  className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
              <div className={home.discount}><p>{item?.discount}%</p></div>
              <div className={home.infos}>
              <h4 className={home.name}>{item?.title}</h4>
              <p className={home.desc}>{item?.desc}</p>
              <div className={home.priceSection}>
              <p className={home.oldPrice}>{item?.price} DH</p>
              <p className={home.price}>{item?.price2} DH</p>
              </div>
              </div>
              </Link>
               </div>
          );
        })}
      </div>
  </div>
)}

{page === "page4" && (
  <div style={{textAlign:"left"}}>
        <p style={{cursor:"pointer",backgroundColor:"red",color:"white",width:"fit-content",paddingLeft:"8px",paddingRight:"8px",borderRadius:"2px",height:"25px"}} onClick={()=> setPage("home")}>الرجوع للصفحة الرئيسية</p>
       <div className={home.products}>
        {products4C?.map((item,index)=>{
          return(
            <div className={home.product}>
              <Link style={{textDecoration:"none"}}>
              <img src={item?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(item?.id);setUpdateCollection("products4"); setUpdateDocumentIndex(index);setShowUpdate(true)}}  className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
              <div className={home.discount}><p>{item?.discount}%</p></div>
              <div className={home.infos}>
              <h4 className={home.name}>{item?.title}</h4>
              <p className={home.desc}>{item?.desc}</p>
              <div className={home.priceSection}>
              <p className={home.oldPrice}>{item?.price} DH</p>
              <p className={home.price}>{item?.price2} DH</p>
              </div>
              </div>
              </Link>
               </div>
          );
        })}
      </div>
  </div>
)}

{page === "page5" && (
  <div style={{textAlign:"left"}}>
        <p style={{cursor:"pointer",backgroundColor:"red",color:"white",width:"fit-content",paddingLeft:"8px",paddingRight:"8px",borderRadius:"2px",height:"25px"}} onClick={()=> setPage("home")}>الرجوع للصفحة الرئيسية</p>
       <div className={home.products}>
        {products5C?.map((item,index)=>{
          return(
            <div className={home.product}>
              <Link style={{textDecoration:"none"}}>
              <img src={item?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(item?.id);setUpdateCollection("products5"); setUpdateDocumentIndex(index);setShowUpdate(true)}}  className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
              <div className={home.discount}><p>{item?.discount}%</p></div>
              <div className={home.infos}>
              <h4 className={home.name}>{item?.title}</h4>
              <p className={home.desc}>{item?.desc}</p>
              <div className={home.priceSection}>
              <p className={home.oldPrice}>{item?.price} DH</p>
              <p className={home.price}>{item?.price2} DH</p>
              </div>
              </div>
              </Link>
               </div>
          );
        })}
      </div>
  </div>
)}
{page === "page6" && (
  <div style={{textAlign:"left"}}>
        <p style={{cursor:"pointer",backgroundColor:"red",color:"white",width:"fit-content",paddingLeft:"8px",paddingRight:"8px",borderRadius:"2px",height:"25px"}} onClick={()=> setPage("home")}>الرجوع للصفحة الرئيسية</p>
       <div className={home.products}>
        {products6C?.map((item,index)=>{
          return(
            <div className={home.product}>
              <Link style={{textDecoration:"none"}}>
              <img src={item?.imgUrl} alt="" />
              <div onClick={()=> {setUpdateId(item?.id);setUpdateCollection("products6"); setUpdateDocumentIndex(index);setShowUpdate(true)}}  className={home.edit} style={{position:"absolute",top:"20%",left:"45%",backgroundColor:"white",width:"35px",height:"35px",borderRadius:"100px",justifyContent:"center",alignItems:"center",display:"flex",border:"1px solid orange"}}><img style={{width:"15px",height:"15px"}} src={edit} alt="" /></div>
              <div className={home.discount}><p>{item?.discount}%</p></div>
              <div className={home.infos}>
              <h4 className={home.name}>{item?.title}</h4>
              <p className={home.desc}>{item?.desc}</p> 
              <div className={home.priceSection}>
              <p className={home.oldPrice}>{item?.price} DH</p>
              <p className={home.price}>{item?.price2} DH</p>
              </div>
              </div>
              </Link>
               </div>
          );
        })}
      </div>
  </div>
)}





    

        </div> 
    </div>
  )
}


const Admin = () => {

    const [logged,setLogged] = useState(false);
    const [password,setPassword] = useState(null);
    const [user,setUser] = useState(null);
    const [data,setData] = useState([]);
    const [open2,setOpen2] = useState(false)
  const [deleteName,setDeleteName] = useState("")
  const [deleteId,setDeleteId] = useState("")
  const [cahnged,setChanged] = useState(false);
  const [nav,setNav] = useState("gestion")

  const getLoging = async()=>{
    const log = await JSON.parse(localStorage.getItem("cozimaAdmin"));

    if (log != null) {
      setLogged("logged")
    }
  }

  useEffect(()=>{
    getLoging()
  },[])




    const getData=async()=>{
        
      const querySnapshot = await getDocs(collection(db, "commandes"));
        const array =[];
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  array.push(doc.data());
});

console.log(array[2]?.commande[0]);
setData(array); 

    }


    const login =async(e)=>{
        e.preventDefault();
        const docRef = doc(db, "admin", "private");
        const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
  if (password === docSnap.data().password && user === docSnap.data().user) {
    setLogged(true);
    localStorage.setItem("cozimaAdmin",JSON.stringify("logged"));
  }else{
    alert("wrong User/Password")
  }
} else {
  console.log("No such document!");
}
    }
    
    useEffect(()=>{
        if (data.length < 1) {
            getData();
        }
    },[])

    
const deleteData = async()=>{
    await deleteDoc(doc(db, "commandes", deleteId));
    setDeleteId("");
    setChanged(!cahnged);
    setOpen2(false)
  }
  
  useEffect(()=>{
    getData();
  },[cahnged])

  const logout=()=>{
    localStorage.removeItem("cozimaAdmin");
    setLogged(false)
  }
  const [total,setTotal] = useState(0);

  useEffect(()=>{
    if (nav === "commandes") {
      setTotal(0);
    }
  },[nav]);

  return (
    <div className={admin.admin}>
      {logged ? (
        <div className={admin.dashboard}>
            <center>
            <div className={admin.header}>
                <div className={admin.userName}><h3>Admin</h3> <button onClick={logout}>Log out</button></div>
                <div className={admin.menu}>
                    <p onClick={()=> setNav("commandes")}>Commandes</p>
                    <p onClick={()=> setNav("messages")}>Messages</p>
                    <p onClick={()=> setNav("gestion")}>Gestion de produits</p>
                </div>
            </div>
            {nav === "commandes" && (
              <div className={admin.commandes}>
              {data.map((item,index)=> { 
                  return(
                  <CommandeItems item={item} index={index} setOpen2={setOpen2} setDeleteName={setDeleteName} setDeleteId={setDeleteId} total={total} setTotal={setTotal}/>
              )})}

          </div>
            )}
            {nav === "gestion" && (
              <GestionProduits/>
            )} 
            {nav === "messages" && (
              <Messages/>
            )}
            </center>
            {open2 && (
            <div className={admin.addModal}>
            <div className={admin.modal}>
            <div style={{position:"relative"}} className={admin.modalContainer}>
            <h2>Delete {deleteName} ?</h2>
            <button onClick={deleteData} style={{backgroundColor:"#0B5EDBF5",color:"white",border:"1px solid gray",borderRadius:"3px",width:"80px",height:"26px",marginRight:"5px",cursor:"pointer"}}>Yes</button><button onClick={()=> setOpen2(false)} style={{backgroundColor:"red",color:"white",border:"1px solid gray",borderRadius:"3px",width:"80px",height:"26px",cursor:"pointer"}}>No</button>
        </div>
            </div>
        </div>
        )}
        </div>
      ):(
        <div className={admin.log}>
            <center style={{width:"100%"}}>
                <div className={admin.formLogin}>
                <form onSubmit={login}>
                    <h3>Login as admin</h3>
                    <input onChange={(e)=> setUser(e.target.value)} placeholder="Nom d'utilisateur" type="text" required/>
                    <input onChange={(e)=> setPassword(e.target.value)} placeholder='Password' type="password" required/>
                    <button type='submit'>Log in</button>
                </form>
                </div>
            </center>
        </div>
      )}
    </div>
  )
}

export default Admin
