import React from 'react'
import messageStyle from "./Message.module.css"

const Message = ({message}) => {
  return (
    <div className={messageStyle.message}>
      <p>{message}</p>
    </div>
  )
}

export default Message
