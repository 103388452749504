import React, { useState } from 'react'
import contact from "./Contact.module.css"
import { doc, setDoc } from 'firebase/firestore';
import db from '../Firebase';
import { v4 as uuidv4 } from 'uuid';

const Contact = () => {
    const [name,setName] = useState(null)
    const [email,setEmail] = useState(null)
    const [message,setMessage] = useState(null)
    const sendMessage=async(e)=>{
        e.preventDefault();
        const id = uuidv4();
        await setDoc(doc(db, "messages", id), {
            name: name,
            email: email,
            message: message,
            id:id
          }).then(()=>{
            alert("Votre message a été bien envoyer");
            setName("");
            setEmail("");
            setMessage("");
          });

    }
  return (
    <div className={contact.contact}>
      <div className={contact.contactContainer}>
      <iframe src="https://maps.google.com/maps?q=35%C2%B010%2716.5%22N+2%C2%B056%2710.5%22W&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0"></iframe>
        <div className={contact.box}>
            <div className={contact.formContainer}>
                <h3>Contact us</h3>
                <form onSubmit={sendMessage}>
                    <input value={name} onChange={(e)=> setName(e.target.value)} type="text" placeholder='Entrer votre nom' required/>
                    <input value={email} onChange={(e)=> setEmail(e.target.value)} type="email" placeholder='Entrer votre email' required/>
                    <textarea value={message} onChange={(e)=> setMessage(e.target.value)} style={{width:"100%",position:"relative"}} name="" id=""  rows="10" placeholder='Message...' required></textarea>
                    <button type='submit'>Envoyer</button>
                </form>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
