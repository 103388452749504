import React, { useEffect, useState } from 'react'
import cartStyle from "./Cart.module.css"
import { useCartBadge } from '../ContextPage';
import { v4 as uuidv4 } from 'uuid';
import { deleteDoc, doc, setDoc } from "firebase/firestore"; 
import db from '../Firebase';
import Message from '../Messages/Message';
import Footer from './Footer';


const Cart = () => {
  const {cart,setCart} = useCartBadge();
  const [cartItems, setCartItems] = useState([]);
  const [total,setTotal] = useState(0);
  const [show,setShow] = useState(false);
  const [name,setName] = useState(null);
  const [telephone,setTelephone] = useState(null);
  const [adresse,setAdresse] = useState(null)
  const [city,setCity] = useState(null)
  const [message,setMessage] = useState(false)
  const [telephone2,setTelephone2] = useState(null);

  


  const getCart = async()=>{
    const cartItems = await JSON.parse(localStorage.getItem("cozimaCart"));
    if (cartItems != null) {
      setCartItems(cartItems);
    }else{
      setCartItems([])
      setTotal(0)
      setCart(0)
    }
  }
 useEffect(()=>{
  getCart();
 },[])


 const getTotal = ()=>{
  cartItems.forEach((item)=>{
    setTotal(prev => prev + Number(item.total));
  })
 }
 useEffect(()=>{
  if (cartItems.length > 0) {
    getTotal()
  }
 },[cartItems])


 const deleting= async(id)=>{
  const cartItems = await JSON.parse(localStorage.getItem("cozimaCart"));
  const newArray = []
  for (let i = 0; i < cartItems.length; i++) {
    if (id !== i) {
      newArray.push(cartItems[i]);
    }
  }
  ;
  console.log(newArray);
  localStorage.setItem("cozimaCart",JSON.stringify(newArray));
  setTotal(0)
  getCart();
  setCart(prev => prev - 1);
}


const commander =async(e)=>{
  e.preventDefault();


  if (telephone !== telephone2) {
    alert("رقم الهاتف غير مطابق");
  }else{
    
  const uid = uuidv4();

  // Add a new document in collection "cities"
  await setDoc(doc(db, "commandes", uid), {
    name:name,
    telephone:telephone,
    adresse:adresse,
    city:city,
    commande:cartItems,
    id:uid,
    total:total,
  });
  
  setShow(false)
  setMessage(true)
  localStorage.removeItem("cozimaCart");
  getCart()
  }
}



useEffect(()=>{
  if (message) {
      
setTimeout(() => {
  setMessage(false);
}, 3500);
  }
},[message]);




  return (
    <div className={cartStyle.cart}>
      <div className={cartStyle.cartBox}>
        <div className={cartStyle.tableSession}>
          <h1 style={{marginTop:"10px"}}>Panier</h1>
        <table>
      <thead>
        <tr>
          <th className={cartStyle.lwl}>Identifiant</th>
          <th>Nom</th>
          <th className={cartStyle.talt}>Quantité</th>
          <th className={cartStyle.talt}>Prix</th>
          <th className={cartStyle.talt}>Suprimer</th>
        </tr>
      </thead>
      <tbody>
        {cartItems?.map((product,index) => (
          <tr key={index}>
            <td className={cartStyle.lwl}>{index + 1}</td>
            <td>{product.name}</td>
            <td className={cartStyle.talt}>{product.quantity}</td>
            <td className={cartStyle.talt}>{product.total} DH</td>
            <td className={cartStyle.talt} style={{textAlign:"center"}}>
              <button onClick={()=> deleting(index)}>Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <div className={cartStyle.bottom}>
    <h3>Prix total : {total} DH</h3>
    <button onClick={()=> setShow(true)}>Commander</button>
    </div>
        </div>
      </div>
      {show && (
          <div className={cartStyle.modal}>
            <div className={cartStyle.modalContainer}>
              <div className={cartStyle.modalTitle}>
              <h4 style={{marginLeft:"20px",marginTop:"-15px"}} onClick={()=> setShow(false)}>X</h4>
              <h3 style={{textAlign:"right",marginBottom:"15px",marginRight:"40px"}}>اكمل عملية الطلب</h3>
              </div>
              <div className={cartStyle.formContainer}>
              <center style={{width:"100%",marginTop:"10px"}}>
              <form onSubmit={commander}>
              <p>1 ـ معلومات التواصل</p>
                <input onChange={(e)=> setName(e.target.value)} placeholder='الاسم الكامل' type="text" required/>
                <p>2 ـ معلومات ارسال المنتج</p>
                <input onChange={(e)=> setTelephone(e.target.value)} placeholder='رقم الهاتف' type="text" required/>
                <input onChange={(e)=> setAdresse(e.target.value)} placeholder='العنوان' type="text" required/>
                <input onChange={(e)=> setCity(e.target.value)} placeholder='المدينة' type="text" required/>
                <input onChange={(e)=> setTelephone2(e.target.value)} placeholder='رقم الهاتف' type="text" required/>

                <button type='submit'>تاكيد الطلب</button>
              </form>
              </center>
              </div>
            </div>
          </div>
        )}
        {message && (
        <Message message={"Commande bien effectuer"}/>
      )}
      <br /><br />
<Footer/>
    </div> 
  )
}

export default Cart
