// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


const firebaseConfig = {
  apiKey: "AIzaSyDlh_7g0dFpGE8E-_MhI7_NGQwIsK2PHts",
  authDomain: "cozima-project.firebaseapp.com",
  projectId: "cozima-project",
  storageBucket: "cozima-project.appspot.com",
  messagingSenderId: "584405611288",
  appId: "1:584405611288:web:4ab469e1371e0edba4a46d",
  measurementId: "G-M1Z6YP5CYS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db
