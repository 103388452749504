import React, { useState } from 'react'
import "./App.css"
import { Link } from 'react-router-dom'
import { useCartBadge } from './ContextPage'
import menu from "./assets/menu.png"
import logo from "./assets/logo.png"
import cartImg from "./assets/cart.png"


const Nav = () => {
  const {cart,setCart} = useCartBadge();
  const [menuActive,setMenuActive] = useState(false);

  return (
    <div className="nav">
      <div className='navBar'>
       <div className="leftSide"><Link style={{textDecoration:"none"}} to="/"> <img style={{width:"150px",height:"30px",backgroundColor:"black",padding:"5px",borderRadius:"5px"}} src={logo} alt="" /></Link><Link style={{textDecoration:"none"}} to="/cart"> <div style={{display:"flex"}}> <img style={{width:"35px",marginLeft:"5px"}} src={cartImg} alt="" /> <span style={{backgroundColor:"orange",color:"white",width:"25px",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"100px",height:"25px"}}>{!cart ? 0 : cart}</span>  </div></Link>  </div>
       <div className="rightSide">
        <Link style={{textDecoration:"none",color:"black"}} to="/"><p>Home</p></Link> 
        <p>About</p>
        <Link style={{textDecoration:"none",color:"black"}} to="/contact"><p>Contact</p></Link>

        <img onClick={()=> setMenuActive(!menuActive)} src={menu} alt="" />
       </div>
    </div>
    {menuActive && (
      <div className='phoneNav'>
      <Link style={{textDecoration:"none",color:"black"}} to="/"><p>HOME</p></Link>
      <Link style={{textDecoration:"none",color:"black"}}><p>ABOUT</p></Link>
      <Link style={{textDecoration:"none",color:"black"}} to="/contact"><p>CONTACT US</p></Link>
      </div>
    )}
    </div>
  )
}

export default Nav
