import React, { useEffect, useState } from 'react'
import home from "./Home.module.css"
import { collection, getDocs } from 'firebase/firestore';
import db from '../Firebase';
import { Link, useNavigate } from 'react-router-dom';
import { useCartBadge } from '../ContextPage';
import Message from '../Messages/Message';


function Products3() {
  const {setCart,products3C,setProducts3C,} = useCartBadge();
  const [products,setProducts] = useState([]);
  const [message,setMessage] = useState(false)

  const navigate = useNavigate();


  const addDetailsInfos =async(name,description,oldPrice,price,discount,img,id)=>{
    const details = {
      name:name,
      description:description,
      oldPrice:oldPrice,
      price:price,
      discount:discount,
      img:img,
      id:id,

    }
    localStorage.setItem("details",JSON.stringify(details))
  }

  const addToCart =async(name,description,price,img,id)=>{
    const cartItems = {
        name:name,
        description:description,
        price:price,
        total:price,
        quantity:1,
        img:img,
        id:id
    }
    const cart = await JSON.parse(localStorage.getItem("cozimaCart"));
    if (cart != null) {
        let newCart = [];
        newCart.push(...cart,cartItems);
        localStorage.setItem("cozimaCart",JSON.stringify(newCart));
    }else{
        localStorage.setItem("cozimaCart",JSON.stringify([cartItems]));
    }
    setCart(prev => prev + 1);
    setMessage(true);
  }


  useEffect(()=>{
    if (message) {
        
  setTimeout(() => {
    setMessage(false);
  }, 1500);
    }
  },[message]);



  const getProducts=async()=>{
    const querySnapshot = await getDocs(collection(db, "products3"));
    const array = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    array.push(doc.data());
    setProducts(array)
  });
  }


  useEffect(()=>{
    if (products3C.length < 1) {
      getProducts();
    }
  },[]);

  useEffect(()=>{
    if (products3C.length < 1) {
      setProducts3C(products);
    }
  },[products]);

  return (
    <div>
      <div className={home.products}>
        
        {products3C?.map((item,index)=>{
          return(
            <div className={home.product}>
              <Link onClick={()=> addDetailsInfos(item?.title,item?.desc,item?.price,item?.price2,item?.discount,item?.imgUrl,item?.id)} style={{textDecoration:"none"}} to={`/productDetails/products3/${item.id}`}>
              <img src={item?.imgUrl} alt="" />
              <div className={home.discount}><p>{item?.discount}%</p></div>
              <div className={home.infos}>
              <h4 className={home.name}>{item?.title}</h4>
              <p className={home.desc}>{item?.desc}</p>
              <div className={home.priceSection}>
              <p className={home.oldPrice}>{item?.price} DH</p>
              <p className={home.price}>{item?.price2} DH</p>
              </div>
              </div>
              </Link>
              <button className={home.button} onClick={()=> {addToCart(item?.title,item?.desc,item?.price2,item?.imgUrl,item?.id); navigate("/cart")} }><p style={{color:"white",fontSize:"10px",top:"50px"}} className={home.oldPrice}>{item?.price} DH</p>Acheter<p style={{color:"white",fontSize:"10px"}} className={home.price}>{item?.price2} DH</p></button>
              <button onClick={()=> addToCart(item?.title,item?.desc,item?.price2,item?.imgUrl,item?.id)} ><p style={{color:"white",fontSize:"10px"}} className={home.oldPrice}>{item?.price} DH</p>Ajouter au panier<p style={{color:"white",fontSize:"10px"}} className={home.price}>{item?.price2} DH</p></button>
            </div>
          );
        })}
      </div>
      {message && (
        <Message message={"Produit bien ajoutée"}/>
      )}
    </div>
  )

}

export default Products3