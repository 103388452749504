import React, { createContext, useContext, useState } from 'react';
import { useEffect } from 'react';

const ContextPage = createContext();

export const ContextPageProvider = ({ children }) => {
  const [log, setLog] = useState(false);
  const [cart, setCart] = useState("");
  const [bannerC,setBannerC] = useState([]);
  const [banner2C,setBanner2C] = useState([]);
  const [productsC,setProductsC] = useState([]);
  const [products2HomeC,setProducts2HomeC] = useState([]);
  const [products1C,setProducts1C] = useState([]);
  const [products2C,setProducts2C] = useState([]);
  const [products3C,setProducts3C] = useState([]);
  const [products4C,setProducts4C] = useState([]);
  const [products5C,setProducts5C] = useState([]);
  const [products6C,setProducts6C] = useState([]);
  const [pack1C,setPack1C] = useState([])
  const [pack2C,setPack2C] = useState([])
  const [carouselC,setCarouselC] = useState([]);
  const [carousel2C,setCarousel2C] = useState([]);


     


  const getCart = async()=>{
    const cartItems = await JSON.parse(localStorage.getItem("cozimaCart"));
    if (cartItems != null) {
      setCart(cartItems?.length);
    }else{
      setCart("")
    }
  }
 useEffect(()=>{
  getCart();
 },[cart])
  
  return (
    <ContextPage.Provider value={{ log, setLog, cart, setCart,bannerC,setBannerC,banner2C,setBanner2C,productsC,setProductsC,pack1C,setPack1C,pack2C,setPack2C,carouselC,setCarouselC,carousel2C,setCarousel2C,products1C,setProducts1C,products2C,setProducts2C,products3C,setProducts3C,products4C,setProducts4C,products5C,setProducts5C,products6C,setProducts6C,products2HomeC,setProducts2HomeC}}>
      {children}
    </ContextPage.Provider> 
  );
};

export const useCartBadge = () => {
  return useContext(ContextPage); 
};
